const en = {
    translation: {
        /*  'contact.subject.need' */
        contact: {
            subject: {
                title: 'Subject',
                need: 'I need accommodation but I can\'t find dates',
                property: 'I am a homeowner',
                colaborator: 'I am a collaborator',
                other: 'Others',
            },
            role: {
                title: 'Role',
                student: 'Student',
                property: 'Property',
                nomada: 'Nomada',
                other: 'Other',
            }
        },
        account: {
            active: 'active',
            account: 'account',
            my: 'my',
            active_account: 'Active Account',
            has_account: 'Log in into your account',
            no_account: 'Create your account now',
            init: 'Login',
            reg: 'Register',
            title: 'My Account',
            links: {
                dashboard: 'Dashboard',
                profile: 'Profile',
                favorite: 'Favorites',
                pendingReserved: 'Pending Reserved',
                bills: 'Payments',
                changePassword: 'Change Password',
            },
            profile: {
                profile: 'My Profile',
                name: 'Name',
                lastname: 'Lastname',
                email: 'Email',
                dni: 'DNI/Passport',
                address: 'Address',
                phone_number: 'Phone Number',
                postal_code: 'Postal Code',
                city: 'City',
                state: 'State',
                country: 'Country'
            },
            dashboard: {
                welcome: 'Hi',
                text: 'From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.'
            },
            changePassword: {
                title: 'Change Password',
                currentPassword: 'Current Password',
                password: 'New Password',
                confirmPassword: 'Confirm Password'
            }

        },
        generals: {
            saveChanges: 'Save changes',
            active: 'Active',
            viewRoom: 'See Rooms',
            goToHome: 'Go to home',
        },
        topbar: {
            spanish: 'Spanish',
            english: 'English',
            country: 'Spain'
        },
        home: {
            welcome: 'Welcome',
            to: 'to',
            accomodationType: {
                title: 'Accomodation Type',
                value1: 'Rooms',
                value2: 'Coliving'
            },
            zone: 'In what zone?',
            date: 'For what date?',
            university: 'University',
            links: {
                home: 'Home',
                know: 'About us',
                service: 'Services',
                contact: 'Contact',
                blog: 'Blog',
                faq: 'FAQS',
                login: 'Login',
                register: 'Register',
                logout: 'Logout',
                admin: 'Admin',
                myAccount: 'My Account',
            },
            text: 'Find your ideal accommodation.',
            search: 'Search',
            mainservices: 'Our Main Services',
            servicestitle: 'Our Services',
            alojamiento: 'Accommodations in established areas.',
            alojamientotxt: 'At helloflatmate, we act as a filter before your search; therefore, all the properties we manage are located in established and secure areas of Valencia and Moncada.',
            contrato: 'Contract.',
            contratotxt: 'It is crucial to have a rental contract for legal residence. All our accommodations follow a contractual model based on the Civil Code, ensuring equal rights and responsibilities for landlords and tenants.',
            atencion: 'Attention to flatmates.',
            atenciontxt: 'flatmates support is available from 9 am to 5 pm, Monday to Friday. Emergencies 24/7: Perfect for when you forget your keys at home outside of office hours.',
            habitacion: 'Prepared accommodations.',
            habitaciontxt: 'We ensure that accommodations are clean and inspected for the contract start date so you can enjoy a comfortable welcome.',
            mantenimiento: 'Mantenimientos propios.',
            mantenimientotxt: 'The accommodations with a 5-month contract are painted and inspected twice a year; for apartments with contracts of 10 and 11 months, this review is conducted once a year. If any issues arise during your stay, contact us via WhatsApp during business hours to coordinate immediate technical assistance.',
            suministros: 'Alta de suministros',
            suministrostxt: 'All our accommodations, except for hello studios, come with electricity, water, and wifi internet connections activated.',
            more: 'MUCH MORE THAN A ROOM!!',
            moretxt: 'We specialize in managing accommodations for students and digital nomads in properties under our direction in Valencia and Moncada.',
            moretxt1: 'We ensure that our residences are situated in established areas, providing contracted, furnished lodgings with activated utilities, cleaned, and inspected by our maintenance staff.',
            footer: {
                frase: 'Collaborating since 2011 in the creation of dignified accommodations for students and digital nomads.'
                ,
                we: {
                    title: 'Links',
                    iam: 'About us',
                    location: 'Where to find us?',
                    contact: 'Contact',
                    video: 'Look at this video',
                },
                faq: {
                    title: 'Frequently question',
                },
                medium: {
                    title: 'News',
                },
                terms: 'Terms & Conditions',
                cookies: 'Cookies',
                legalWarning: 'Legal Warning',
                copyrigth: 'All rigth reserved'
            }


        },
        services: {
            readMore: 'Read more',
            viewMore: 'View more',
            hide: 'Hide',
            headerTitle: 'What we offer?',
            subheader: 'Services',
            hellorooms: {
                title: 'Ready-to-Move-In.',
                list: {
                    title: 'Furthermore, we provide assurances to property owners to encourage:',
                    li1: 'Utilities under the property\'s name.',
                    li2: 'Flexible contracts of 5, 10, or 11 months, depending on the property.',
                    li3: 'A deposit of only 300€.',
                    li4: 'Contracts without the need for guarantors.'
                },
                p1: 'Our welcoming hello rooms are specifically designed for international and national students seeking a space ready to inhabit from the very beginning, as they come to Valencia for less than a year.',
                p2: 'Since landlords are not legally obligated to deliver clean and inspected accommodations, we provide this preparation service for those who prefer to avoid the initial tasks.',
                p3: 'It includes all activated services, along with internet connection, and offers the opportunity to live with flatmates of similar ages and lifestyles.',
                p4: 'This product, tailored to meet very specific needs of a particular audience, comes with a management fee of 459.80 euros, VAT included.'
            },
            landlord: {
                realTitle: 'helloLanlord',
                list: {
                    title: 'Furthermore, we provide assurances to property owners to encourage:',
                    li1: 'Utilities under the property\'s name.',
                    li2: 'Flexible contracts of 5, 10, or 11 months, depending on the property.',
                    li3: 'A deposit of only 300€.',
                    li4: 'Contracts without the need for guarantors.'
                },
                p1: ' hello landlord are rooms managed by trusted property owners and are specifically designed for international and national students looking for a ready-to-live space, especially as they come to Valencia for less than a year.',
                p2: 'Since landlords are not legally obliged to deliver clean and inspected accommodations, we provide this preparation service for those opting for this arrangement, allowing them to bypass initial tasks.',
                p3: 'It includes all activated services, along with an internet connection, offering the opportunity to live with flatmates of similar ages and lifestyles.',
                p4: 'This product, designed to meet very specific needs of a particular audience, comes with a management fee of 459.80 euros, including VAT.'
            },
            studio: {
                title: '',
                list: {

                },
                p1: 'If you\'re coming to Valencia to pursue your entire degree and seek maximum independence, hello studios is for you. ',
                p2: 'In the case of properties with long-term lease contracts (minimum of 5 years) regulated by the Urban Leases Act (LAU) and according to Housing Law 12/23, the tenant is exempt from paying a commission for intermediary services between the landlord and tenant.',
                p3: 'This legislation is designed to facilitate access to housing for young individuals in the workforce, making it easier for them to emancipate and cope with other costs associated with renting, such as furniture, water, electricity, and gas services, as well as the two months\'rent deposit that may be required.',
                p4: 'In our portfolio, you\'ll find apartments ranging from semi-furnished to unfurnished, some requiring utility activation, and others not (electricity, water, and gas).'
            },
            coliving: {
                title: '',
                list: {
                    title: 'Additionally, we offer assurances to property owners:',
                    li1: 'Utilities under the property\'s name.',
                    li2: 'Flexible contracts of 5, 10, or 11 months, depending on the property.',
                    li3: 'A deposit of only 300€.',
                    li4: 'Contracts without the need for guarantors.'
                },
                p1: 'Our welcoming and modern hello coliving accommodations are specifically designed for digital nomads seeking a ready-to-live space.',
                p2: 'In the coliving context, where we value collaboration and community, we provide additional services for a more comprehensive experience. Alongside all the guest services offered by helloflatmate, we include bi-weekly room cleaning, bed linen changes, and supplies up to 50 euros per person per month.',
                p3: 'It encompasses all activated services, along with an internet connection, offering the opportunity to live with coliving peers of similar ages and lifestyles.',
                p4: 'This product, designed specifically to meet the needs of a coliving community, comes with a management fee of 459.80 euros, including VAT.'
            },
        },
        payments: {
            ok: 'Payment executed successfully',
            ko: 'Payment pending to process',
            index: 'Payment status'
        },
        result: {
            search: 'Search results'
        }
    }
};

export default en;