import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import BlogGrid from './blog-components/blog-grid';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';

const BlogGridPage = () => {
    return <div>
        <TopBar/>
        <Navbar />
        <PageHeader headertitle="Noticias, Novedades e Información de interés"  subheader="Blog" />
        <BlogGrid />
        <Footer />
    </div>
}

export default BlogGridPage

