import React, { useState } from "react";
import { useAuth } from "../../../../contexts/AuthContextProvider";
import { useTranslation } from "react-i18next";
import { toastAction } from "../../../../utils/functions";
import ErrorMessage from "../../../ErrorMessage";


const ProfileForm = ({ isProfile, onUpdate }) => {
    const { t } = useTranslation();
    const { user, updateProfile, authLoading, handleLoadingEnd } = useAuth();
    const [data, setData] = useState({});

    const handleSubmit = e => {
        e.preventDefault();
        const body = Object.fromEntries(new FormData(e.target).entries());
        const invalid = Object.keys(body).some(el => body[el] == '')
        if (invalid) {
            setData(body)
        } else {
            setData({})
            onUpdate && onUpdate();
            toastAction(updateProfile(body), 'Cargando...', () => handleLoadingEnd())
                .catch(err => console.log(err))
        }

    }

    return (<form id="contact-form" action="mail.php" method="post" onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-md-6 relative">
                <div className="input-item input-item-name ltn__custom-icon">
                    <input type="text" name="name" placeholder={t('account.profile.name')} defaultValue={user?.name} />
                </div>
                {data?.name == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>
            <div className="col-md-6">
                <div className="input-item input-item-name ltn__custom-icon">
                    <input type="text" name="lastname" placeholder={t('account.profile.lastname')} defaultValue={user?.lastname} />
                </div>
                {data?.lastname == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>
            <div className="col-md-6">
                <div className="input-item input-item-email ltn__custom-icon">
                    <input type="email" name="email" placeholder={t('account.profile.email')} defaultValue={user?.email} />
                </div>
                {data?.email == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>
            <div className="col-md-6">
                <div className="input-item input-item-doc ltn__custom-icon">
                    <input type="text" name="dni" placeholder={t('account.profile.dni')} defaultValue={user?.dni} />
                </div>
                {data?.dni == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>
            <div className="col-md-6">
                <div className="input-item input-item-doc ltn__custom-icon">
                    <input type="text" name="addres" placeholder={t('account.profile.address')} defaultValue={user?.addres} />
                </div>
                {data?.addres == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>

            <div className="col-md-6">
                <div className="input-item input-item-phone ltn__custom-icon">
                    <input type="text" name="phone" placeholder={t('account.profile.phone_number')} defaultValue={user?.phone} />
                </div>
                {data?.phone == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>
            <div className="col-md-6">
                <div className="input-item input-item-doc ltn__custom-icon">
                    <input type="text" name="postalcode" placeholder={t('account.profile.postal_code')} defaultValue={user?.postalcode} />
                </div>
                {data?.postalcode == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>
            <div className="col-md-6">
                <div className="input-item input-item-doc ltn__custom-icon">
                    <input type="text" name="city" placeholder={t('account.profile.city')} defaultValue={user?.city} />
                </div>
                {data?.city == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>
            <div className="col-md-6">
                <div className="input-item input-item-doc ltn__custom-icon">
                    <input type="text" name="state" placeholder={t('account.profile.state')} defaultValue={user?.state} />
                </div>
                {data?.state == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>
            <div className="col-md-6">
                <div className="input-item input-item-doc ltn__custom-icon">
                    <input type="text" name="country" placeholder={t('account.profile.country')} defaultValue={user?.country} />
                </div>
                {data?.country == '' && <ErrorMessage message={'Este campo es requerido'} />}
            </div>

        </div>
        <div className="btn-wrapper mt-0">
            <button className="btn theme-btn-1 btn-effect-1 text-uppercase w-100" disabled={authLoading} type="submit">{user?.actived > 1 ? t('generals.saveChanges') : t('account.active_account')}</button>
        </div>
        <p className="form-messege mb-0 mt-20" />
    </form>)
}



export default ProfileForm;