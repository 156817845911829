import React, { useEffect } from 'react';
import { socialNetworks } from '../../utils/navigation';
import { useLayout } from '../../contexts/LayoutContext';
import { email, langs } from '../../utils/environment';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const TopBar = () => {
	const { time, setLanguage } = useLayout();
	const { pathname } = useLocation();
	const { t } = useTranslation();
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth', left: 0 })
	}, [pathname])

	const handleClick = code => {
		i18next.changeLanguage(code);
		localStorage.setItem('lang', code);
		setLanguage(code);
	}


	return (
		<div>
			<div className="ltn__header-top-area section-bg-6">
				<div className="container">
					<div className="row">
						<div className="col-md-7">
							<div className="ltn__top-bar-menu">
								<ul className='d-flex align-items-center flex-wrap'>
									<li><a href={`mailto:${email}`} className='f-monserrat-label'><i className="icon-mail text-info" /> {email}</a></li>
								</ul>
							</div>
						</div>
						<div className="col-md-5">
							<div className="top-bar-right text-end">
								<div className="ltn__top-bar-menu">
									<ul>
										<li>
											<div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
												<ul>
													<li><a href="#" className="dropdown-toggle"><span className="active-currency">{t(langs.find(el => i18next.language === el?.code)?.nativeName)}</span></a>
														<ul className='bg-white' style={{ zIndex: 999999 }}>
															{
																langs.map(el => <li key={el?.code} className='text-secondary-color-2'><a className='cursor-pointer' onClick={() => handleClick(el?.code)}>{t(el?.nativeName)}</a></li>)
															}
														</ul>
													</li>
												</ul>
											</div>
										</li>
										<li>
											{/* ltn__social-media */}
											<div className="ltn__social-media">
												<ul>
													{
														socialNetworks.map((el, index) => <li key={`social-${index}`}><a href={`${el?.link}`} target='_blank' rel='noopener noreferrer' title={el.title} className='social text-secondary-color '><i className={el?.icon} /></a></li>)
													}
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


export default TopBar