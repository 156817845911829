import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { strRandom } from '../../utils/functions';
import { Card } from '../section-components/featured-item-v1';
class BlogGrid extends Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'
		return (
			<div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
				<div className="container">
					<div className="row">
						{
							new Array(10).fill(() => strRandom(25)).map(el => <BlogCard publicUrl={publicUrl} key={el} el={el} />)
						}
					</div>
					{/*  */}
				</div>
				<div className="row mx-0">
					<div className="col-lg-12">
						<div className="ltn__pagination-area text-center">
							<div className="ltn__pagination">
								<ul>
									<li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
									<li><Link to="#">1</Link></li>
									<li className="active"><Link to="#">2</Link></li>
									<li><Link to="#">3</Link></li>
									<li><Link to="#">...</Link></li>
									<li><Link to="#">10</Link></li>
									<li><Link to="#"><i className="fas fa-angle-double-right" /></Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const BlogCard = ({ el, publicUrl }) => (<div className="col-lg-4 col-sm-6 col-12">
	<div className="ltn__blog-item ltn__blog-item-3">
		<div className="ltn__blog-img">
			<Link to="/blog-details"><img src={publicUrl + "assets/img/blog/1.jpg"} alt="#" /></Link>
		</div>
		<div className="ltn__blog-brief">
			<div className="ltn__blog-meta">
				<ul className='text-secondary-color-2'>
					<li className="ltn__blog-author go-top">
						<Link to="/team-details"><i className="far fa-user" />por: Admin</Link>
					</li>
					<li className="ltn__blog-tags go-top">
						<Link to="/service"><i className="fas fa-tags" />Servicios</Link>
					</li>
				</ul>
			</div>
			<h3 className="ltn__blog-title text-secondary-color-2"><Link to="/blog-details" className='fw-bold'>Common Engine Oil Problems and Solutions</Link></h3>
			<div className="ltn__blog-meta-btn">
				<div className="ltn__blog-meta">
					<ul className='text-secondary-color-2'>
						<li className="ltn__blog-date"><i className="far fa-calendar-alt" />June 24, 2020</li>
					</ul>
				</div>
				<div className="ltn__blog-btn">
					<Link to="/blog-details">Leer m&aacute;s</Link>
				</div>
			</div>
		</div>
	</div>
</div>)

export default BlogGrid;
