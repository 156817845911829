import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { email, phoneNumber } from '../../utils/environment';

class ContactInfo extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__contact-address-area mb-90">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 h-100">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow mb-0">
							<div className="ltn__contact-address-icon">
								<img src={publicUrl + "assets/img/icons/10.png"} alt="Icon Image" />
							</div>
							<h3>Direcci&oacute;n de correo</h3>
							<p className='truncate text-nowrap'>{email}</p>
						</div>
					</div>
					<div className="col-lg-4 h-100">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow mb-0">
							<div className="ltn__contact-address-icon">
								<img src={publicUrl + "assets/img/icons/11.png"} alt="Icon Image" />
							</div>
							<h3>N&uacute;mero Telef&oacute;nico</h3>
							<p className='truncate text-nowrap'><a href={`tel:${phoneNumber.split(' ').join('')}`}>{phoneNumber}</a></p>
						</div>
					</div>
					<div className="col-lg-4 h-100">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow mb-0">
							<div className="ltn__contact-address-icon">
								<img src={publicUrl + "assets/img/icons/12.png"} alt="Icon Image" />
							</div>
							<h3>Lugar</h3>
							<p className='truncate text-nowrap'>
								Valencia, Espa&ntilde;a</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default ContactInfo