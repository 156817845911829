import React from "react";


const PdfViewer = ({ source }) => {
    const resizeIframe = e => {
        var iframe = e.target;
        iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        var iframeHeight = Math.max(iframeDocument.body.scrollHeight, iframeDocument.documentElement.scrollHeight);
        iframe.style.height = iframeHeight + 'px';
    }

    return <iframe width={'100%'} height={'4900px'} src={source} seamless onLoad={resizeIframe} />
}

export default PdfViewer;