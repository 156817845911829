import React, { useEffect } from 'react';
import TopBar from './global-components/topbar';
import Navbar from './global-components/navbar-v2';
import BannerV4 from './section-components/banner-v4';
import Video from './section-components/video-v4';
import Featuresv1 from './section-components/featured-item-v1';
import Footer from './global-components/footer';
import ServiceV5 from './section-components/service-v1';
import SearchForm from './section-components/search-form';
import Category from './section-components/category-v3';

const Home_V8 = () => {

    useEffect(() => {
        const $ = window.$;
        $('.ltn__slide-one-active').slick({
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            dots: false,
            fade: true,
            cssEase: 'linear',
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
            nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        arrows: false,
                        dots: false,
                    }
                }
            ]
        })

        return () => {
            $('.ltn__slide-one-active').slick('destroy');
        }


    }, [])


    return (
        <div>
            <TopBar />
            <Navbar />
            <BannerV4 />
            <Video />
            <SearchForm />
            <Category />
            <ServiceV5 />
            <Featuresv1 customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---" />
            <Footer />
        </div>
    )
}

export default Home_V8

