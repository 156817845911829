import { numberAsEuro } from "../utils/functions";

export const rooms = [
    {
        id: Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2),
        location: 'Belmont Gardens, Chicago',
        price: numberAsEuro(Math.random() * 1000),
        title: 'New Apartment Nice View',
        description: `Beautiful Huge 1 Family House In Heart Of
        Westbury. Newly Renovated With New Wood`,
        bedrooms: parseInt(Math.random() * 8),
        bathrooms: parseInt(Math.random() * 2),
        squareFt: parseInt(Math.random() * 16),
    },
    {
        id: Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2),
        location: 'Belmont Gardens, Chicago',
        price: numberAsEuro(Math.random() * 1000),
        title: 'New Apartment Nice View',
        description: `Beautiful Huge 1 Family House In Heart Of
        Westbury. Newly Renovated With New Wood`,
        bedrooms: parseInt(Math.random() * 8),
        bathrooms: parseInt(Math.random() * 2),
        squareFt: parseInt(Math.random() * 16),
    },
    {
        id:  Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2),
        location: 'Belmont Gardens, Chicago',
        price: numberAsEuro(Math.random() * 1000),
        title: 'New Apartment Nice View',
        description: `Beautiful Huge 1 Family House In Heart Of
        Westbury. Newly Renovated With New Wood`,
        bedrooms: parseInt(Math.random() * 8),
        bathrooms: parseInt(Math.random() * 2),
        squareFt: parseInt(Math.random() * 16),
    },
    {
        id: Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2),
        location: 'Belmont Gardens, Chicago',
        price: numberAsEuro(Math.random() * 1000),
        title: 'New Apartment Nice View',
        description: `Beautiful Huge 1 Family House In Heart Of
        Westbury. Newly Renovated With New Wood`,
        bedrooms: parseInt(Math.random() * 8),
        bathrooms: parseInt(Math.random() * 2),
        squareFt: parseInt(Math.random() * 16),
    },
];