import { Service } from "./Service";

class AuthService extends Service {

    login = async (data) => await this.client.post('/login', data);

    register = async (data) => await this.client.post('/register', data);

    changePassword = async (data) => await this.client.post('/change-password', data);

    logout = async () => await this.client.post('/logout');

    profile = async (token = null) => {

        this.client.interceptors.request.use(config => {
        if (token) {
             config.headers['Authorization'] = token;
        }
        return config;
        });
        return await this.client.get('/profile')
    };

    updateProfile = async data => await this.client.patch('/update-profile', data);

    activeAccount = async data => await this.client.post('/active-account', data);

}

export default new AuthService();