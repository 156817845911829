import React, { Component, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import { appName, baseUrlServer } from '../../utils/environment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../contexts/AuthContextProvider';
import { useTranslation } from 'react-i18next';
import LogoOriginal from '../../assets/logo-helloflatmate-original.jpg'
import { getForm, toastAction, toastLoading } from '../../utils/functions';
import toast from 'react-hot-toast';
import { socialNetworks } from '../../utils/navigation';


const publicUrl = process.env.PUBLIC_URL + '/';

const NavbarV2 = ({ CustomClass }) => {

	const { t } = useTranslation();
	const { user, handleLogout } = useAuth();
	const adminFormRef = useRef();

	const handleAdmin = e => {
		toastLoading('Redirigiendo...')
		const data = {
			url: `${baseUrlServer}/login-admin`,
			api_token: user?.api_token,
		}
		const form = getForm(data, adminFormRef.current)
		user?.is_admin && form.submit();
	}

	return (
		<div>
			<header className={"ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4--- " + CustomClass}>
				{/* ltn__header-middle-area start */}
				<div className="ltn__header-middle-area ltn__header-sticky ">
					<div className="container">
						<div className="row">
							<div className="col">
								<div className="site-logo-wrap">
									<div className="site-logo go-top">
										<a onClick={() => window.location.href = '/'} className="cursor-pointer d-flex align-items-center">
											<img src={LogoOriginal} width={200} alt="Logo" />
										</a>
									</div>
									<div className="get-support clearfix d-none">
										<div className="get-support-icon">
											<i className="icon-call" />
										</div>
										<div className="get-support-info">
											<h6>Get Support</h6>
											<h4><a href="tel:+123456789">123-456-789-10</a></h4>
										</div>
									</div>
								</div>
							</div>
							<div className="col header-menu-column">
								<div className="header-menu d-none d-xl-flex align-items-center go-top">
									<nav>
										<div className="ltn__main-menu">
											<ul className='text-secondary-color-2'>
												<li className={window.location.toString().substring(window.location.toString().lastIndexOf('/')).length === 1 ? 'link-active' : ''}><Link className='text-secondary-color-2' to="/">{t('home.links.home')}</Link>
												</li>
												<li className={`${window.location.toString().includes('/about') ? 'link-active' : ''}`}><Link className='text-secondary-color-2' to="/about">{t('home.links.know')}</Link>
												</li>
												<li className={window.location.toString().includes('/service') ? 'link-active' : ''} ><Link className='text-secondary-color-2' to="/service">{t('home.links.service')}</Link>
												</li>
												<li className={window.location.toString().includes('/contact') ? 'link-active' : ''}><Link className='text-secondary-color-2' to="/contact">{t('home.links.contact')}</Link>
												</li>
												<li><a className='text-secondary-color-2' target='_blank' rel="noopener noreferrer" href="https://www.helloflatmate.com/site/blog/">{t('home.links.blog')}</a>
												</li>
												{user?.is_admin && (<li><a onClick={handleAdmin} className='text-secondary-color-2 cursor-pointer'>{t('home.links.admin')}
												</a>
												</li>)}
												<li className={window.location.toString().includes('/faq') ? 'link-active' : ''}><Link className='text-secondary-color-2' to="/faq">{t('home.links.faq')}</Link></li>
												<li />
											</ul>

										</div>
									</nav>
									{/* user-menu */}
									<div className="ltn__drop-menu user-menu">
										<ul className='p-0'>
											<li>
												<Link className='cursor-pointer' to={!user ? '/login' : '/my-account'}>{!user ? <i className="icon-user" /> : <span className='bg-done rounded-circle fw-bold d-flex align-items-center justify-content-center mb-0 text-uppercase' style={{ height: '40px', width: '40px' }}>{user?.name?.charAt(0)}</span>} </Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col--- ltn__header-options ltn__header-options-2 ">
								{/* Mobile Menu Button */}
								<div className="mobile-menu-toggle d-xl-none">
									<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
										<svg viewBox="0 0 800 600">
											<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
											<path d="M300,320 L540,320" id="middle" />
											<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
										</svg>
									</a>
								</div>



							</div>
						</div>
					</div>
				</div>
				{/* ltn__header-middle-area end */}
			</header >
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu py-2">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
						<div className="site-logo">
							<Link to="/">
								<img src={publicUrl + "assets/img/logo-helloflatmate.png"} width={40} height={40} alt="Logo" />
								<h3 className='mb-0 f-monserrat-title truncate' style={{ fontSize: '20px' }}>{appName}</h3>
							</Link>
						</div>
						<button className="ltn__utilize-close">×</button>
					</div>
					<div className="ltn__utilize-menu">
						<ul>
							<li className={window.location.toString().substring(window.location.toString().lastIndexOf('/')).length === 1 ? 'link-active' : ''}><Link className='text-secondary-color-2' to="/">{t('home.links.home')}</Link>
							</li>
							<li className={`${window.location.toString().includes('/about') ? 'link-active' : ''}`}><Link className='text-secondary-color-2' to="/about">{t('home.links.know')}</Link>
							</li>
							<li className={window.location.toString().includes('/service') ? 'link-active' : ''} ><Link className='text-secondary-color-2' to="/service">{t('home.links.service')}</Link>
							</li>
							<li className={window.location.toString().includes('/contact') ? 'link-active' : ''}><Link className='text-secondary-color-2' to="/contact">{t('home.links.contact')}</Link>
							</li>
							<li className={window.location.toString().includes('/blog-grid') ? 'link-active' : ''}><a className='text-secondary-color-2' target='_blank' rel="noopener noreferrer" href="https://www.helloflatmate.com/site/blog/">{t('home.links.blog')}</a>
							</li>
							{user?.is_admin && (<li><a onClick={handleAdmin} className='text-secondary-color-2 cursor-pointer'>{t('home.links.admin')}
							</a>
							</li>)}
							<li className={window.location.toString().includes('/faq') ? 'link-active' : ''}><Link className='text-secondary-color-2' to="/faq">{t('home.links.faq')}</Link></li>
							<li />
							{!user ?
								(<li className={window.location.toString().includes('/login') ? 'link-active' : ''}><Link className='text-secondary-color-2' to="/login">{t('home.links.login')}</Link></li>)
								:
								(
									<>
										<li className={

											window.location.toString().includes('/my-account')
												&&
												!window.location.search
												? 'link-active' : ''}><Link className='text-secondary-color-2' to="/my-account">{t('home.links.myAccount')}</Link>

										</li>
										<li className={window.location.search.includes('bills') ? 'link-active' : ''}><Link className='text-secondary-color-2' to="/my-account?section=bills">{t('account.links.bills')}</Link>

										</li>
										<li className={window.location.toString().includes('/login') ? 'link-active' : ''}><a className='text-secondary-color-2' onClick={handleLogout} >{t('home.links.logout')}</a></li>
									</>
								)
							}
						</ul>
					</div>
					<div className="ltn__social-media-2 mt-auto d-flex justify-content-between">
						<ul>
							<li>
								<Link className='cursor-pointer' to={!user ? '/login' : '/my-account'}>{!user ? <i className="icon-user" /> : <span className='bg-done rounded-circle fw-bold d-flex align-items-center justify-content-center mb-0 text-uppercase' style={{ height: '40px', width: '40px' }}>{user?.name?.charAt(0)}</span>} </Link>
							</li>
						</ul>
						<ul>
							{socialNetworks.map(el => <li key={el?.title}><a href={el?.link} title={el?.title}><i className={el?.icon} /></a></li>)}
						</ul>
					</div>
				</div>
			</div>
			<form ref={adminFormRef} />
		</div >
	)
}

export default NavbarV2