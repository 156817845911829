import { Redirect, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../contexts/AuthContextProvider";
import React, { useEffect } from "react";

const Guess = ({ children }) => {
    const { user } = useAuth();
    const history= useHistory()
    useEffect(()=>{
      user && history.goBack()
    },[user])
    return children;
}

export default Guess;