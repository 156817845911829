import React, { useEffect, useRef, useState } from "react";
import { time as mockTime } from "../mocks/home";
import { createContext, useContext } from "react"
import { Toaster } from "react-hot-toast";
import HomeService from "../services/HomeService";
import Modal from "../components/global-components/Modal";
import { timeApiKye, valenciaLatitud, valenciaLongitud } from "../utils/environment";
import ProfileForm from "../components/shop-components/partials/my-account/profileForm";
import { useQuery } from "../hooks/useQuery";
import { toastAction } from "../utils/functions";
import AuthService from "../services/AuthService";
import { useAuth } from "./AuthContextProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const LayoutContext = createContext(null);

export const markAsFavorite = async (id, callback = null) => {
    const response = await HomeService.markAsFavorite(id);
    callback && callback(response?.data);
    return response;
}

const search = async data => (await HomeService.search(data)).data;

export const LayoutContextProvider = ({ children, setLanguage }) => {
    const [time, setTime] = useState(null);
    const [rooms, setRooms] = useState(null);
    const [srooms, setSROoms] = useState(null)
    const [mounted, setMounted] = useState(false)
    const [zones, setZones] = useState(null);
    const [dates, setDates] = useState(null);
    const [universities, setUniversities] = useState(null);
    const [searchFormStatus, setSearchFormStatus] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const params = useQuery();
    const { setUser, user, setAccessToken } = useAuth();
    const history = useHistory();
    const handleActive = () => {
        const activationToken = params.get('activation_token');
        const accessToken = params.get('accessToken');
        activationToken && accessToken && toastAction(AuthService.activeAccount({ activationToken, accessToken }), 'Activando Cuenta...').then(res => {
            history.replace('/');
            setUser(res?.data?.data)
            setAccessToken(accessToken);
            user?.actived < 2 && setShowModal(true)
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        Promise.all([
            HomeService.getZones(),
            HomeService.getUniversities(),
            HomeService.getDates()
        ]).then((values) => {
            setTimeout(() => {
                const setters = [setZones, setUniversities, setDates];
                for (const i in values) {
                    setters[i](values[i]?.data);
                }
            }, 1000);
        }).catch(_ => {
        }).finally(() => setSearchFormStatus(false))


        HomeService.getTime(timeApiKye, `${valenciaLatitud},${valenciaLongitud}`).then(resp => setTime(resp.data)).catch(err => console.log(err))
        handleActive()
    }, [])
    const resetParams = () => {
        setZones(null);
        setUniversities(null);
        setDates(null)
    }


    const state = {
        time,
        rooms,
        dates,
        setDates,
        zones,
        setZones,
        universities,
        setUniversities,
        setRooms,
        markAsFavorite,
        srooms,
        setSROoms,
        mounted,
        setMounted,
        search,
        searchFormStatus,
        setSearchFormStatus,
        resetParams,
        setLanguage
    };

    const onUpdate = () => {
        setShowModal(false)
        history.replace('/')
    }

    return (<LayoutContext.Provider value={state}>
        {children}
        {user && (user?.actived == 1 || user?.actived == 0) && <Modal centered title='Activar Cuenta' showModal={showModal} setShowModal={setShowModal}>
            <div className='px-5'>
                <ProfileForm onUpdate={onUpdate} />
            </div>
        </Modal>}
        <Toaster
            position="bottom-right"
            reverseOrder={false}
        />
    </LayoutContext.Provider>);
}

export const useLayout = () => {
    const context = useContext(LayoutContext);
    if (context === undefined) {
        return {};
    }
    return context;
};

