import React from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "../../../../contexts/AuthContextProvider"
import { toastAction } from "../../../../utils/functions";

export default function ChangePassword() {
    const { t } = useTranslation()
    const { changePassword, authLoading, handleLoadingEnd } = useAuth();
    const handleSubmit = e => {
        e.preventDefault();
        const body = Object.fromEntries(new FormData(e.target).entries());
        toastAction(changePassword(body), 'Cargando...', handleLoadingEnd)
    }

    return (<div className="tab-pane fade" id="ltn_tab_1_9">
        <div className="ltn__myaccount-tab-content-inner">
            <div className="account-login-inner">
                <form action="#" className="ltn__form-box contact-form-box" onSubmit={handleSubmit}>
                    <h5 className="mb-30">{t('account.changePassword.title')}</h5>
                    <input type="password" name="old_password" placeholder={t('account.changePassword.currentPassword')} />
                    <input type="password" name="password" placeholder={t('account.changePassword.password')} />
                    <input type="password" name="password_confirmation" placeholder={t('account.changePassword.confirmPassword')} />
                    <div className="btn-wrapper mt-0">
                        <button className="theme-btn-1 btn btn-block" disabled={authLoading} type="submit">{t('generals.saveChanges')}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    )
}