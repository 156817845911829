import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../section-components/featured-item-v1';
/* import { rooms } from '../../mocks/rooms'; */
import { useLayout } from '../../contexts/LayoutContext';
import Sidebar from './shop-sidebar';
import { toastAction } from '../../utils/functions';

let publicUrl = process.env.PUBLIC_URL + '/'

const ShopGridV1 = () => {
	const [filter, setFilter] = useState('');
	const { rooms, setRooms, search } = useLayout();
	const handleFilter = e => setFilter(e.target.value.trim());
	const [page, setPage] = useState(null);
	const [params, setParams] = useState({});

	useEffect(() => {
		if (page != null) {
			toastAction(search({ ...params, page }), 'Cargando...').then(response => {
				setRooms(response?.data);
				window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
			}).catch(el => console.log(el));
		}
	}, [page])

	return (<div>
		<div className="ltn__product-area ltn__product-gutter mb-100">
			<div className="container">
				<div className="row">
					<Sidebar setParams={setParams} />
					<div className="col-lg-8">
						{
							rooms?.data.length ? (<div className="ltn__shop-options">
								<ul>
									<li className='ms-auto'>
										<div className="showing-product-number text-right">
											<span>Mostrando {rooms?.data?.length} de {rooms?.total} resultados</span>
										</div>
									</li>
								</ul>
							</div>) : null
						}

						<div className="tab-content ">
							<div className="tab-pane fade active show" id="liton_product_grid">
								<div className="ltn__product-tab-content-inner ltn__product-grid-view">
									<div className="row">
										{rooms?.data.length ? <div className="col-lg-12">
											{/* Search Widget */}
											<div className="ltn__search-widget mb-30">
												<div action="#">
													{/* <input type="text" name="search" placeholder="Escribir aqu&iacute;" onChange={handleFilter} /> */}
													{/* 				<button type="submit"><i className="fas fa-search" /></button> */}
												</div>
											</div>
										</div> : null}
										{/* ltn__product-item */}

										{
											rooms?.data
												.filter(el => el?.title?.toLowerCase().includes(filter.toLowerCase()) || el?.description?.toLowerCase().includes(filter.toLowerCase()))
												.map((el, index) => <div className="col-sm-6 col-12">
													<Card key={el?.id} el={el} />
												</div>
												)
										}
										{
											rooms && !rooms?.data?.length ? (<div className='col-12 col-md-6'>
												<h1 className='text-secondary-color-2 fw-bold'>Upss!! No se encontraron resultados</h1>
												<Link to={'/'} className='text-secondary-color-2'>Volver al Home</Link>
											</div>) : !rooms && <div className='d-flex gap-2 align-items-center'>
												<h1 className='text-secondary-color-2 fw-bold'>Cargando las habitaciones</h1>
												<div class="snippet ms-3 mb-1" data-title="dot-falling">
													<div class="stage">
														<div class="dot-falling"></div>
													</div>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
						{
							rooms?.last_page > 1 && (<div className="ltn__pagination-area text-center">
								<div className="ltn__pagination">
									<ul>
										<li className='cursor-pointer' onClick={() => setPage(page => page > 1 ? page - 1 : 1)}><a><i className="fas fa-angle-double-left" /></a></li>
										{
											new Array(rooms?.last_page).fill(1).map((_, index) => <li className={`cursor-pointer ${(index + 1) == page ? 'active' : ''}`} onClick={() => setPage(index + 1)}><a key={`page-${index}`}>{index + 1}</a></li>)
										}
										<li className='cursor-pointer' onClick={() => setPage(page => page < rooms?.last_page ? page + 1 : rooms?.last_page)}><a><i className="fas fa-angle-double-right" /></a></li>
									</ul>
								</div>
							</div>)
						}
					</div>
				</div>
			</div>
		</div>

	</div >)
}

export default ShopGridV1