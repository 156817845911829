import React, { Component, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { getFormatedDate, getLanguage, getForm, numberAsEuro, toastAction } from '../../utils/functions';
import { socialNetworks } from '../../utils/navigation';
import i18next, { use } from 'i18next';
import { useLayout } from '../../contexts/LayoutContext';
import RoomService from '../../services/RoomService';
import HomeService from '../../services/HomeService';
import { useAuth } from '../../contexts/AuthContextProvider';
import ProfileForm from './partials/my-account/profileForm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Modal from '../global-components/Modal';
import Profile from './partials/my-account/profile';
import { sliders } from '../../mocks/home';
import { SliderItem } from '../section-components/banner-v7';
import { faqs } from '../../mocks/faq';
import { FaqCard } from '../section-components/faq-v1';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { baseUrlImagesRoom, baseUrlYoutube, googleMapsApiKey } from '../../utils/environment';
import LoadingIcon from '../LoadingIcon';
import { Slideshow } from '../global-components/SlideShow';
import DinamicSliderItem from '../global-components/DinamicSlider';
import { useTranslation } from 'react-i18next';
let publicUrl = process.env.PUBLIC_URL + '/'
let flag = false;
const $ = window.$;
const ShopDetails = ({ room, suggestions, setRoom }) => {

	console.log({ room });
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [loadingContact, setLoadingContact] = useState(false);
	const [dates, setDates] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const { user } = useAuth();
	const history = useHistory();
	const descriptionRef = useRef();
	const formRef = useRef(null)
	const dateRef = useRef(null)
	const selectRef = useRef();
	const opinionref = useRef();
	const [video, setVideo] = useState(null);
	const descriptionbarrioRef = useRef();
	const [dataShow, setDataShow] = useState({ description: true, map: true, zone: false, opinion: false });


	const { isLoaded } = useLoadScript({
		googleMapsApiKey: googleMapsApiKey,
	});
	const center = useMemo(() => ({ lat: parseFloat(room?.home?.latitud) || 0, lng: parseFloat(room?.home?.longitud) || 0 }), [room]);
	const faqAreRef = useRef();

	const handleSubmit = e => {
		e.preventDefault();
		if (!user) {
			history.push('/login')
		}
		else if (user?.actived == 1 || user?.actived == 0) {
			setShowModal(true)
		} else {
			const contract = dateRef.current.querySelector('li.selected').getAttribute('data-value');
			const code = room?.id;
			toastAction(RoomService.reserveRoom({ contract, code }))
				.then(res => {
					const form = getForm(res.data?.data, formRef.current, 'POST');
					form.submit();
				})
				.catch(err => ({ error: err }))
		}

	}

	const handleContact = e => {
		setLoadingContact(true)
		e.preventDefault();
		const body = Object.fromEntries(new FormData(e.target).entries());
		toastAction(HomeService.contact(body))
			.catch(e => console.log(e))
			.finally(() => setLoadingContact(false));
	}
	useEffect(() => {
		if (descriptionRef && room) {
			descriptionRef.current.innerHTML = room?.description;
			const aTgs = descriptionRef.current.querySelectorAll('a');
			let urlVideo = null;
			for (const el of aTgs) {
				if (el?.href.includes('youtu.be')) {
					urlVideo = el?.href.substring(el?.href.lastIndexOf('/') + 1);
					break;
				} else if (el?.href.includes('youtube')) {
					urlVideo = el?.href.substring(el?.href.lastIndexOf('=') + 1);
					break;
				}
			}
			console.log({ urlVideo });
			urlVideo && setVideo(urlVideo);

		}
		if (faqAreRef && faqAreRef?.current && room?.home) {
			const floorData = faqAreRef?.current.querySelector('#room div')
			floorData && (floorData.innerHTML = getLanguage() === 'es' ? room?.description : room?.description_en)


			const homeData = faqAreRef?.current.querySelector('#floor div')
			homeData && (homeData.innerHTML = getLanguage() === 'es' ? room?.home?.description : room?.home?.description_en)

			const conditionData = faqAreRef?.current.querySelector('#condition div')
			conditionData && (conditionData.innerHTML = room?.home?.othersconditions?.conditions)

			const billsData = faqAreRef?.current.querySelector('#bills div')
			billsData && (billsData.innerHTML = room?.home?.otherscheckout?.checkout)

			const repairData = faqAreRef?.current.querySelector('#repair div')
			repairData && (repairData.innerHTML = room?.home?.othersreparation?.reparation)

			const normsData = faqAreRef?.current.querySelector('#norms div')
			normsData && (normsData.innerHTML = room?.home?.othersrules?.rules)

			const checkinData = faqAreRef?.current.querySelector('#checkin div')
			checkinData && (checkinData.innerHTML = room?.home?.otherscheckin?.checkin)

			const kitchen = faqAreRef?.current.querySelector('#kitchen div')
			kitchen && (kitchen.innerHTML = getLanguage() == 'es' ? room?.home?.descriptionkitchen : room?.home?.descriptionkitchen_en)


			const otherServices = faqAreRef?.current.querySelector('#other-services div')
			otherServices && (otherServices.innerHTML = getLanguage() == 'es' ? room?.home?.othersservices.services : room?.home?.othersservices.services_en)

			const othersbills = faqAreRef?.current.querySelector('#othersbills div')
			othersbills && (othersbills.innerHTML = getLanguage() == 'es' ? room?.home?.othersbills.bills : room?.home?.othersbills.bills_en)



		}

		if (descriptionbarrioRef && descriptionbarrioRef?.current && room?.home) {
			descriptionbarrioRef.current.innerHTML = getLanguage() === 'es' ? room?.home?.barrio?.description : room?.home?.barrio?.description_en;
		}

		if (opinionref && opinionref?.current && room?.home && room?.home?.opinion) {
			opinionref.current.innerHTML = getLanguage() === 'es' ? room?.home?.opinion?.opinion : room?.home?.opinion?.opinion_en;
		}
	}, [room, descriptionRef, descriptionbarrioRef])

	useEffect(() => {
		if (dates) {
			const ul = dateRef.current.querySelector('ul.list')
			if (ul?.childElementCount == 1) {
				ul.innerHTML += dates.reduce((prev, current) => prev + `<li data-value="${current?.id}" class="option">${current?.name}</li>`, '');;
			}
		}
	}, [dates, selectRef])

	useEffect(() => {
		window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
	}, [])

	useEffect(() => {

		if (room?.photo) {
			$('.ltn__slide-one-active').slick('destroy');
			$('.ltn__slide-one-active').slick({
				autoplay: true,
				autoplaySpeed: 2000,
				arrows: true,
				dots: false,
				fade: true,
				cssEase: 'linear',
				infinite: true,
				speed: 300,
				slidesToShow: 1,
				slidesToScroll: 1,
				prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
				nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							arrows: false,
							dots: false,
						}
					}
				]
			})

			HomeService.getAvailableDates(room?.code_home).then(res => setDates(res.data?.data))

		}
	}, [room])

	return (<div className="ltn__shop-details-area pt-5 pb-10">
		<div className="container">
			<div className="row pb-5">
				<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner">
						<div className="ltn__blog-meta">
							<ul>
								{
									room && <li className="ltn__blog-category">
										<a className='text-white cursor-pointer' disabled={loading}>
											{numberAsEuro(room?.price)}
											<form action='' ref={formRef} />
										</a>
									</li>}
								{
									room ? <li className="ltn__blog-date">
										<label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> {room?.home?.zone?.zone}</label>

									</li> : null}

								{
									room ? <li className="ltn__blog-date">
										<label><span className="ltn__secondary-color"><i className="fa fa-users" /></span> {getLanguage() === 'es' ? room?.home?.sex?.sex : room?.home?.sex?.sex_en}</label>

									</li> : null}
							</ul>
						</div>
						<div className="ltn__slider-area ltn__slider-3  section-bg-2">
							<div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1 ">
								{room?.photo && room?.photo?.map(el => <DinamicSliderItem url={`${el?.url_photo}`} key={el?.id} />)}
							</div>
						</div>
						<h1 className='text-secondary-color-2 fw-bold'>{room?.title}</h1>
						<div className="ltn__faq-area">
							<div className="row">
								<div className="col-12">
									<div className="ltn__faq-inner ltn__faq-inner-2">
										{/* card */}
										<div id="accordion_2" ref={faqAreRef}>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#description`} aria-expanded="true">
													Descripci&oacute;n
												</h6>
												<div id={'description'} className="collapse show" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">
														<p ref={descriptionRef}></p>
													</div>
												</div>
											</div>
											{video && (<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#video`} aria-expanded="true">
													Video
												</h6>
												<div id={'video'} className="collapse show" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">
														<iframe width="100%" height="315"
															src={`https://www.youtube.com/embed/${video}?autoplay=0&mute=0`}>
														</iframe>
													</div>
												</div>
											</div>)}

											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#condition`} aria-expanded="false">
													Condici&oacute;n de alquiler
												</h6>
												<div id={'condition'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#room`} aria-expanded="false">
													Habitaci&oacute;n
												</h6>
												<div id={'room'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#floor`} aria-expanded="false">
													Piso
												</h6>
												<div id={'floor'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#othersbills`} aria-expanded="false">
													Facturas
												</h6>
												<div id={'othersbills'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#kitchen`} aria-expanded="false">
													Sobre nuestra Cocina
												</h6>
												<div id={'kitchen'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#repair`} aria-expanded="false">
													Mantenimiento
												</h6>
												<div id={'repair'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#norms`} aria-expanded="false">
													Normas de conviviencia
												</h6>
												<div id={'norms'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#checkin`} aria-expanded="false">
													Check In
												</h6>
												<div id={'checkin'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#bills`} aria-expanded="false">
													Checkout
												</h6>
												<div id={'bills'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#location`} aria-expanded="true">
													Nuestra ubicaci&oacute;n
												</h6>
												<div id={'location'} className="collapse show" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">
														<div style={{ height: '400px' }} className={!dataShow?.map ? 'd-none' : ''}>
															{!isLoaded ? (
																<h1><LoadingIcon />&nbps;Cargando mapa</h1>
															) : (
																<GoogleMap
																	mapContainerClassName="h-100 w-100"
																	center={center}
																	zoom={10}
																>
																	<Marker position={center} />
																</GoogleMap>
															)}

														</div>
													</div>
												</div>
											</div>
											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#our-zone`} aria-expanded="false">
													Nuestro Barrio
												</h6>
												{room && (<div id={'our-zone'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">
														<img className={`w-75`}
															style={{ minHeight: '200px', maxHeight: '300px' }}
															alt='Imagen del barrio' src={`https://www.helloflatmate.com/uploads/barrio/${room?.home?.barrio?.id}/${room?.home?.barrio?.filename}`} />
														<p className={`text-secondary-color-2`} ref={descriptionbarrioRef}>

														</p>
													</div>
												</div>)}
											</div>

											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#other-services`} aria-expanded="false">
													Otros Servicios
												</h6>
												<div id={'other-services'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">

													</div>
												</div>
											</div>

											<div className="card">
												<h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#opnion-owner`} aria-expanded="false">
													Opinion del Agente
												</h6>
												{room && (<div id={'opnion-owner'} className="collapse" data-bs-parent="#accordion_2">
													<div className="card-body text-secondary-color-2">
														<div className={`ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 ltn__product-details-review-inner pb-0 mb-0`}>
															{/* comment-area */}
															<div className={`ltn__comment-area`} >
																<div className="ltn__comment-inner">
																	<ul>
																		<li>
																			<div className="ltn__comment-item clearfix">
																				<div className="ltn__commenter-img">
																					<img src={`https://www.helloflatmate.com/uploads/users/${room?.home?.opinion?.agent?.id}/${room?.home?.opinion?.agent?.filename}`} alt="Image" />
																				</div>
																				<div className="ltn__commenter-comment">
																					<h6><a href="#">{room?.home?.opinion?.agent?.name}</a></h6>
																					<div className="product-ratting">
																					</div>
																					<span className="ltn__comment-reply-btn">{getFormatedDate(room?.home?.opinion?.created_at)}</span>
																					<p className='mt-4' ref={opinionref}></p>
																				</div>
																			</div>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>)}
											</div>
										</div>
									</div>
								</div>
							</div>

						</div >
					</div>



					{/* Social Media Widget */}
					<div className="d-none d-md-block widget ltn__social-media-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2 text-secondary-color-2">Siguenos</h4>
						<div className="ltn__social-media-2">
							<ul>
								{
									socialNetworks.map((el, index) => <li key={`single-social-${index}`}><a href={`${el?.link}`} target='_blank' rel="noopener noreferrer" alt={el?.title} title={el?.title}><i className={el?.icon} /></a></li>)
								}
							</ul>
						</div>
					</div>
				</div>
				<div className="col-lg-4"
				>
					<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
						{/* Author Widget */}
						<div className="widget ltn__form-widget">
							<h4 className="ltn__widget-title ltn__widget-title-border-2 text-secondary-color-2">Reservar Habitaci&oacute;n</h4>
							<form onSubmit={handleSubmit} className='row ltn__car-dealer-form-box'>
								{
									room && room?.home && (<label>
										<h5 className='text-secondary-color-2 fw-bold word-break px-2'>Esta vivienda es un {room?.home?.type?.name}</h5>
									</label>)
								}


								<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-12 col-md-12" ref={dateRef}>
									<select className="nice-select text-capitalize">
										<option>{t('home.date')}</option>
									</select>
								</div>
								{
									room && (<label>
										<h5 className='text-secondary-color-2 fw-bold truncate px-2 fs-5'>{numberAsEuro(room?.price)}</h5>
									</label>)
								}
								<button disabled={!dates} type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">{<>Reservar</>}</button>

							</form>
						</div>
						{/* Form Widget */}
						<div className="widget ltn__form-widget">
							<h4 className="ltn__widget-title ltn__widget-title-border-2">Cont&aacute;ctanos</h4>
							<form action="#" onSubmit={handleContact}>
								<input required type="text" name="code" value={`${room ? room?.code_home + "/" + room?.code : 'Cargando...'}`} className='fw-bold' readOnly
									style={{ cursor: 'default' }}
									placeholder={`Código: ${room ? room?.code_home + "/" + room?.code : 'Cargando...'}`} />

								<input required type="text" name="name_contact" placeholder="Nombre*" />
								<input required type="email" name="email_contact" placeholder="Email*" />
								<input required type="text" name="subject_contact" placeholder="Asunto*" />
								<textarea required name="comments_contact" placeholder="Escribir un mensaje" defaultValue={""} />
								<button type="submit" className="btn theme-btn-1" disabled={loadingContact}>Enviar</button>
							</form>
						</div>


						<div className="widget ltn__popular-post-widget go-top">
							<h4 className="ltn__widget-title ltn__widget-title-border-2">Otras Similares</h4>
							<ul>
								{
									suggestions && suggestions.map((el, index) => <CardSugestion setRoom={setRoom} el={el} key={`suggestion-${index}`} />)
								}
							</ul>
						</div>





						{/* Social Media Widget */}
						<div className="d-block d-md-none widget ltn__social-media-widget">
							<h4 className="ltn__widget-title ltn__widget-title-border-2">Follow us</h4>
							<div className="ltn__social-media-2">
								<ul>
									{
										socialNetworks.map((el, index) => <li key={`single-social-pc-${index}`}><a href={`${el?.link}`} target='_blank' rel="noopener noreferrer" alt={el?.title} title={el?.title}><i className={el?.icon} /></a></li>)
									}
								</ul>
							</div>
						</div>

					</aside>
				</div>
			</div>
		</div>
		{
			user && (user?.actived == 1 || user?.actived == 0) && (<Modal centered showModal={showModal} title='Activar su cuenta' setShowModal={setShowModal}>
				<div className='px-5'>
					<ProfileForm onUpdate={() => setShowModal(false)} />
				</div>
			</Modal>)
		}

	</div>)
}


export const CardSugestion = ({ el, setRoom, to }) => {

	const history = useHistory();
	const handleChangeRoom = () => {
		$('.ltn__slide-one-active').slick('destroy');
		setRoom && setRoom(el)
		to && history.push(to)
		window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
	}

	return (<li>
		<div className="popular-post-widget-item clearfix">
			<div className="popular-post-widget-img">
				<Link onClick={handleChangeRoom} to={`/roomview/${el?.code_home}/${el?.code}`}><img src={`${el?.photo[0]?.url_photo}`} alt="#" /></Link>
			</div>
			<div className="popular-post-widget-brief">
				<h6 onClick={handleChangeRoom}><Link to={`/roomview/${el?.code_home}/${el?.code}`}>{el?.type}</Link></h6>
				<div className="ltn__blog-meta">
					<ul>
						<li className="ltn__blog-date">
							<a href="#">{numberAsEuro(el?.price || 0)}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</li>)
}


export default ShopDetails;