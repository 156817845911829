import React, { useEffect, useRef } from "react";


const Modal = ({ noHeader, wrapperDisabled, showModal, setShowModal, children, centered, title = 'Action modal', className = 'w-75', bodyClassName = '' }) => {

    const modalRef = useRef();
    const wrapperRef = useRef();

    const handleHideModal = () => setShowModal && setShowModal(false)

    useEffect(() => {
        if (showModal) {
            modalRef.current.classList.remove('modal-hide')
            wrapperRef.current.classList.remove('pointer-events-none')
            wrapperRef.current.classList.add('modal-wrapper')
            document.body.classList.add('overflow-hidden')
        } else {
            modalRef.current.classList.add('modal-hide')
            wrapperRef.current.classList.add('pointer-events-none')
            wrapperRef.current.classList.remove('modal-wrapper')
            document.body.classList.remove('overflow-hidden')
        }


    }, [showModal])

    return (<div ref={wrapperRef} className={`
    position-fixed
     top-0
      left-0
       w-100 
       vh-100 
       d-flex 
       ${centered ? 'align-items-center' : ''}
       justify-content-center 
       pointer-events-none`}
        style={{ zIndex: 88888888 }} onClick={() => !wrapperDisabled && handleHideModal}>

        <div ref={modalRef} onClick={e => e.stopPropagation()} className={`bg-white custom-modal modal-hide ${className}`}>
            {!noHeader && <div className="custom-modal-header border-bottom d-flex align-items-center justify-content-between">
                <h3 className="text-secondary-color-2 mb-0 fw-bold">{title}</h3>
                <i className="fa fa-times fs-5 cursor-pointer align-self-start" onClick={handleHideModal} />
            </div>}
            <div className={`custom-modal-body ${bodyClassName}`}>
                {children}
            </div>
        </div>
    </div>)
}

export default Modal;