import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import CookiesV1 from './section-components/cookiesv1';

const Cookies = () => {
    return <div>
        <TopBar />
        <Navbar />
        <PageHeader headertitle="POLÍTICA DE COOKIES" />
        <CookiesV1 />
        <Footer />
    </div>
}

export default Cookies;

