import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-grid-v1';
import Footer from './global-components/footer';
import { useQuery } from '../hooks/useQuery';
import { varietyOptions } from '../utils/navigation';
import { useTranslation } from 'react-i18next';

const ShopGrid_V1 = () => {

    const params = useQuery();
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    return <div>
        <Navbar />
        <PageHeader headertitle={t('result.search')} />
        <ShogGrid />
        <Footer />
    </div>
}

export default ShopGrid_V1

