import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import TermV1 from './section-components/terms-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';

const Term_v1 = () => {
    return <div>
        <TopBar />
        <Navbar />
        <PageHeader headertitle="Términos & Condiciones" />
        <TermV1 />
        <Footer />
    </div>
}

export default Term_v1;

