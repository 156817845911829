import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { phoneNumber } from '../../utils/environment';
import { faqs } from '../../mocks/faq';
import { useLayout } from '../../contexts/LayoutContext';
import { CardSugestion } from '../shop-components/shop-details';
import HomeService from '../../services/HomeService';

let publicUrl = process.env.PUBLIC_URL + '/'
const FaqV1 = () => {

    const [srooms, setSROoms] = useState(null)

    useEffect(() => {
        const $ = window.$
        HomeService.getCarouselRooms().then(res => setSROoms(res.data)).catch(err => console.log(err))
        return () => {
            $('.ltn__slide-one-active').slick('destroy');
        }
    }, [])
    return (<div className="ltn__faq-area mb-100">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="ltn__faq-inner ltn__faq-inner-2">
                        {/* card */}

                        <div id="accordion_2">
                            {
                                faqs.map((el, index) => <FaqCard id={`fag-${index}`} key={el?.id} el={el} />)
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <aside className="sidebar-area ltn__right-sidebar">

                        <div className="widget ltn__popular-post-widget go-top">
                            <h4 className="ltn__widget-title ltn__widget-title-border-2">Te puede interesar</h4>
                            <ul>
                                {
                                    srooms && srooms.map((el, index) => <CardSugestion  el={el} key={`suggestion-${index}`} />)
                                }
                            </ul>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div >)
}
export const FaqCard = ({ el, id }) => (
    <div className="card">
        <h6 className="collapsed ltn__card-title text-secondary-color-2 fw-bold" data-bs-toggle="collapse" data-bs-target={`#${id}`} aria-expanded="false">
            {el?.question}
        </h6>
        <div id={id} className="collapse" data-bs-parent="#accordion_2">
            <div className="card-body text-secondary-color-2">
                {
                    el?.answer
                }
            </div>
        </div>
    </div>)
export default FaqV1