import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import LegalWarningV1 from './section-components/legal-warning-v1';

const LegalWarning_v1 = () => {
    return <div>
        <TopBar />
        <Navbar />
        <PageHeader headertitle="Aviso Legal" />
        <LegalWarningV1 />
        <Footer />
    </div>
}

export default LegalWarning_v1;

