import toast from "react-hot-toast"
import React from "react"

const successMessage = 'Operación completada satisfactoriamente';
const errorMessage = 'Ha ocurrido un error inesperado';


export const toastSuccessMessage = (message = '') => toast.success(message || successMessage)


export const toastErrorMessage = (message = '') => toast.error(message || errorMessage)

export const toastActionSuccess = (callback) => {
    const message = callback && callback();
    return <b>{message || successMessage}</b>
};


export const toastActionError = ({ response }, callback) => {
    callback && callback();
    return <b>{response?.data?.message || errorMessage}</b>
};

