import React, { useEffect, useRef, useState } from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner';
import SearchForm from './section-components/search-form';
import Aboutv1 from './section-components/about-v1';
import Counter from './section-components/counter-v1';
import AboutV2 from './section-components/about-v2';
import Featuresv1 from './section-components/features-v1';
import ProSlider from './section-components/product-slider-v1';
import Apartment from './section-components/apartment-v1';
import VideoV1 from './section-components/video-v1';
import Category from './section-components/category-v1';
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import ServiceV5 from './section-components/service-v1';
import TopBar from './global-components/topbar';
import { useLayout } from '../contexts/LayoutContext';
import { Redirect, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getLanguage, toastAction } from '../utils/functions';
import { Card } from './section-components/featured-item-v1';
import { useTranslation } from 'react-i18next';
import { types, varietyOptions } from '../utils/navigation';
import i18next from 'i18next';

const Home_V1 = () => {

    const [rooms, setRooms] = useState(null);
    const { search, setLanguage } = useLayout()
    const { type } = useParams()
    const { t } = useTranslation();
    const roomsRef = useRef();
    const [options, setOptions] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (type && ['helloColiving', 'helloLandlord', 'helloStudio', 'helloRoom'].includes(type)) {
            toastAction(search({ type: types[type] }), 'Cargando Habitaciones...').then(response => {
                setRooms(response?.data?.data);
            }).catch(el => console.log(el));
            const varietyOption = varietyOptions?.find(el => el.category == types[type]);
            varietyOption && setOptions(varietyOption)
        } else if (type) {
            type == 'es' || type == 'en' && setLanguage(type)
            i18next.changeLanguage(type);
            localStorage.setItem('lang', type);
        }

    }, [type])

    return type != 'es' && type != 'en' && (<div>
        <TopBar />
        <Navbar />
        <Banner options={options} />
        <SearchForm />
        <Aboutv1 options={options} roomsRef={roomsRef} />
        <div className='container mt-40' id='rooms' ref={roomsRef}>
            {rooms && <div className='row'>
                <div className='col-12 text-center'>
                    <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color text-secondary-color font-title">Nuestras Habitaciones</h6>
                </div>
            </div>}
            <div className='row'>
                {rooms && rooms?.map(el => <div key={`service-${el?.id}`} className="col-sm-4 col-12">
                    <Card key={el?.id} el={el} />
                </div>)}
            </div>
        </div>
        <ServiceV5 />
        <Footer />
    </div >)
}

export default Home_V1

