import helloLandlord from "../assets/home/helloLandlord.webp"
import helloColiving from "../assets/home/helloColiving.webp"
import helloStudio from "../assets/home/helloStudio.webp"
import helloRoom from "../assets/home/helloRooms.webp"

export const services = [
    {
        icon: 'flaticon-house',
        title: 'home.alojamiento',
        description: 'home.alojamientotxt'
    },
    {
        icon: 'flaticon-official-documents',
        title: 'home.contrato',
        description: 'home.contratotxt'
    },

    {
        icon: 'flaticon-operator',
        title: 'home.atencion',
        description: 'home.atenciontxt'
    },
    {
        icon: 'flaticon-house-1',
        title: 'home.habitacion',
        description: 'home.habitaciontxt'
    },
    {
        icon: 'flaticon-house',
        title: 'home.mantenimiento',
        description: 'home.mantenimientotxt'
    },
    {
        icon: 'flaticon-house-3',
        title: 'home.suministros',
        description: 'home.suministrostxt'
    },
];

export const socialNetworks = [
    {
        icon: 'fab fa-facebook-f',
        link: 'https://www.facebook.com/helloflatmate/',
        title: 'Facebook'
    },
    {
        icon: 'fab fa-instagram',
        link: 'https://www.instagram.com/helloflatmatevlc/',
        title: 'Instagram'
    },
];

export const varietyOptions = [
    {
        category: 2,
        type: 'helloRoom',
        title: 'helloRooms',
        link: '/category',
        key: 'hellorooms',
        ul: 'hellorooms.list.title',
        li: 4,
        p: 4,
        img: 'hellorooms.png',
        background: helloRoom,
        price: '459,80Є inc IVA'

    },
    {
        category: 3,
        type: 'helloColiving',
        title: 'helloColiving',
        link: '/category',
        key: 'coliving',
        ul: 'coliving.list.title',
        li: 4,
        p: 4,
        img: 'hellocoliving.png',
        background: helloColiving,
        price: '459,80Є inc IVA'
    },
    {
        category: 4,
        type: 'helloStudio',
        title: 'helloStudio',
        link: '/category',
        key: 'studio',
        ul: 'studio.list.title',
        li: 0,
        p: 4,
        img: 'hellostudio.png',
        background: helloStudio,
        price: '459,80Є inc IVA'

    },
    {
        category: 5,
        type: 'helloLandlord',
        title: 'helloLandlord',
        link: '/category',
        key: 'landlord',
        ul: 'landlord.list.title',
        li: 4,
        p: 4,
        img: 'hellolandlord.png',
        background: helloLandlord,
        price: '459,80Є inc IVA'
    }
];

export const contactSubjet = [
    'contact.subject.need',
    'contact.subject.property',
    'contact.subject.colaborator',
    'contact.subject.other',
];

export const contactRole = [
    'contact.role.student',
    'contact.role.property',
    'contact.role.nomada',
    'contact.role.other',
];

export const  types = {
    helloColiving: 3,
    helloRoom: 2,
    helloStudio: 4,
    helloLandlord: 5,
};