import React from "react"
import { useAuth } from "../../../../contexts/AuthContextProvider"
import { useTranslation } from "react-i18next";
import { toastAction } from "../../../../utils/functions";
import ProfileForm from "./profileForm";
import { t } from "i18next";
let publicUrl = process.env.PUBLIC_URL + '/'

export default function Profile() {
    const { user, } = useAuth();
    const { t } = useTranslation()

    return (<div className="tab-pane fade" id="ltn_tab_1_2">
        <div className="ltn__myaccount-tab-content-inner">
            {/* comment-area */}
            <div className="ltn__comment-area mb-50">
                <div className="ltn-author-introducing clearfix">
                    <div className="author-info">
                        {/* <h6>Agent of Property</h6> */}
                        <h2>{user?.name}</h2>
                        <div className="footer-address">
                            <ul>
                                <li>
                                    <div className="footer-address-icon">
                                        <i className="icon-placeholder" />
                                    </div>
                                    <div className="footer-address-info">
                                        <p>{user?.addres}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="footer-address-icon">
                                        <i className="icon-call" />
                                    </div>
                                    <div className="footer-address-info">
                                        <p><a href={`tel:${user?.phone}`}>{user?.phone}</a></p>
                                    </div>
                                </li>
                                <li>
                                    <div className="footer-address-icon">
                                        <i className="icon-mail" />
                                    </div>
                                    <div className="footer-address-info">
                                        <p><a href={`mailto:${user?.email}`}>{user?.email}</a></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="ltn__form-box contact-form-box box-shadow white-bg">
                    <h4 className="title-2">{user?.actived == 1 ? t('account.active_account') : t('account.profile.profile')}</h4>
                    <ProfileForm isProfile />
                </div>
            </div>
        </div>
    </div>)
}