export const appName = process.env.REACT_APP_NAME || 'helloflatmate';

export const timeApiKye = process.env.REACT_APP_TIME_API_KEY;

export const wetherBaseUrl = process.env.REACT_APP_TIME_WETHER_BASE_URL;

export const publicUrl = (process.env.PUBLIC_URL || '') + '/';

export const valenciaLatitud = process.env.REACT_APP_VALENCIA_LATITUD;

export const valenciaLongitud = process.env.REACT_APP_VALENCIA_LONGITUD;

export const phoneNumber = '+34 601 158 261';

export const stateName = 'Valencia';

export const email = 'rooms@helloflatmate.com';

export const baseUrlImagesRoom = '';

export const baseUrlImagesHome = 'https://www.helloflatmate.com/uploads/floor';

export const baseUrlYoutube = 'https://www.youtube.com/watch?v=';

export const baseUrlServer = process.env.REACT_APP_BASE_URL_SERVER;

export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const langs = [
    {
        code: 'en', nativeName: 'topbar.english',
    },
    { code: 'es', nativeName: 'topbar.spanish' }
];
