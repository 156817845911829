import toast from "react-hot-toast";
import React from "react";
import { toastActionError, toastActionSuccess } from "./functions/toast";

export const randomUuid = () => new Date.now().toString(36) + Math.random().toString(36).substring(2);

export const numberAsEuro = number => {
    const validatedPrice = !isNaN(number) ? number : 0;
    return new Intl.NumberFormat('sfb', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(validatedPrice)
}

export const strRandom = (amount = 20) => Math.random().toString(amount)
export const getLanguage = () => {
    const locale = localStorage.getItem('lang');
    return validateLocale(locale) ? locale : 'es';

}

const validateLocale = lang => ['en', 'es'].includes(lang)

export const getFormatedDate = (date, opcionesFormato = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
}) => {
    const locale = getLanguage();
    const locales = { en: 'en-US', es: 'es-ES' }
    // Crear un objeto Date a partir de la cadena de fecha
    const fecha = new Date(date);


    // Formatear la fecha utilizando toLocaleString()
    return fecha.toLocaleString(locales[locale || 'es'], opcionesFormato);


}

export const getCustomDate = d => {
    const date = new Date(d);
    const nombresMeses = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre"
    ];
    const nombreMes = nombresMeses[date.getMonth()];
    const anno = date.getFullYear();

    return `${nombreMes} de ${anno}`;

}
export const toastLoading = (message = 'Cargando...') => toast.loading(message)

export const toastAction = async (action, loading = 'Cargando...', callback = null) => {
    return toast.promise(
        action,
        {
            loading,
            success: () => toastActionSuccess(callback),
            error: (err) => toastActionError(err, callback),
        }
    )
}

export const getForm = (data, form, method = 'POST') => {
    const keys = Object.keys(data);
    form.method = method || 'POST';
    form.action = data?.url;
    for (const iterator of keys) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.value = data[iterator];
        input.name = iterator;
        form.appendChild(input);
    }
    return form;
}

export const params = new URLSearchParams(window.location.search);