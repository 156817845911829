import { Service } from "./Service";

class RoomService extends Service {

    search = async data => await this.client.post('/search', data);

    getMyFavoriteRooms = async () => await this.client.get('/get-my-favorite-rooms');

    getMyPendingRooms = async () => await this.client.get('/get-my-pending-rooms');

    getMyBills = async () => await this.client.get('/get-my-bills');

    payPendingBill = async (data) => await this.client.post('/payment-room-mes', data);

    deleteFavoriteRooms = async roomId => await this.client.delete('/delete-room-as-favorite', {
        data: { roomId }
    });

    deletePendingRoom = async roomId => await this.client.delete(`/delete-my-pending-rooms/${roomId}`);

    getRoomById = async (codeHome, codeRoom) => await this.client.get(`/room-by-id/${codeHome}/${codeRoom}`);

    reserveRoom = async (data) => await this.client.post('/verifypayment', data);
}

export default new RoomService();