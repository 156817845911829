import axios from "axios";

export class Service {
    constructor() {
        this.client = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL_SERVER}/api`,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                
            }
        });

        this.client.interceptors.request.use(config => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                config.headers.Authorization = `${token}`
            }

            return config;
        })
    }
}