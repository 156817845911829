
import React from "react";
import "./css/FloatingActionButton.css";

const FloatingActionButton = ({ label, handleClick }) => {

    return (<button
        style={{
            bottom: '40px',
            zIndex: 9999999
        }}
        onClick={handleClick}
        className='floating-action-button position-fixed start-0 px-3 py-2 rounded-circle text-white shadow-md bg-done ms-4'>
        {label}
    </button>)
}

export default FloatingActionButton;