import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { services } from '../../utils/navigation';
import { useTranslation } from 'react-i18next';




const ServiceV5 = () => {
	const { t } = useTranslation();

	return (<div id='services' className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color text-secondary-color font-title">{t('home.servicestitle')}</h6>
						<h1 className="section-title text-secondary-color-2 f-monserrat-title">{t('home.mainservices')}</h1>
					</div>
				</div>
			</div>
			<div className="row  justify-content-center">
				{
					services.map((el, index) => (<div key={'service' + index} className="col-lg-4 col-sm-6 col-12 mb-4">
						<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 h-100">
							<div className="ltn__feature-icon">
								<span><i className={el?.icon} /></span>
							</div>
							<div className="ltn__feature-info mt-auto">
								<h3 className='text-secondary-color-2 font-title'><Link to="#">{t(el?.title)}</Link></h3>
								<p className='text-secondary-color-2'>{t(el?.description)}</p>
							</div>
						</div>
					</div>))
				}
			</div>
		</div>

	</div>)
}



export default ServiceV5

/*
Poner el tamano de letra par aoscar 
Y que sean carruseles las imagenes del card
Disponible mostrarlo solo cuando tenga reservas disponibles
Que se muestren las imagenes del home junto con el home
*/ 