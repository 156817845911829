import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import helloLandlord from "../../assets/home/helloLandlord.webp"
import helloColiving from "../../assets/home/helloColiving.webp"
import helloStudio from "../../assets/home/helloStudio.webp"
import helloRoom from "../../assets/home/helloRooms.webp"



let publicUrl = process.env.PUBLIC_URL + '/'
let imagealt = 'image'


const CategoryV3 = () => {

	const { t } = useTranslation();

	return <div className="ltn__banner-area pt-120 go-top">
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">{t('home.text')}</h6>
						<h1 className="section-title">{t('home.more')}</h1>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-8 col-md-6">
					<Link to="/helloColiving">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg={helloColiving} >
							<div className="ltn__banner-info">
								<h3> helloColiving </h3>
							</div>
						</div>
					</Link>
				</div>
				<div className="col-lg-4 col-md-6">
					<Link to="/helloRoom">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg={helloRoom} >
							<div className="ltn__banner-info">
								<h3>helloRoom</h3>
							</div>
						</div>
					</Link>
				</div>
				<div className="col-lg-4 col-md-6">
					<Link to="/helloLandlord">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg={helloLandlord} >
							<div className="ltn__banner-info">
								<h3>helloLandlord</h3>
							</div>
						</div>
					</Link>
				</div>
				<div className="col-lg-8 col-md-6">
					<Link to="/helloStudio">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg={helloStudio} >
							<div className="ltn__banner-info">
								<h3>  helloStudio</h3>
							</div>
						</div>
					</Link>
				</div>
			</div>
		</div>
	</div >
}

export default CategoryV3