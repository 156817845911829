import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
let publicUrl = process.env.PUBLIC_URL + '/'


const AboutV5 = ({ el }) => {
	const [viewMore, setViewMore] = useState(false)
	const { t } = useTranslation();

	const handleShowMore = () => setViewMore(!viewMore);

	return <div className="ltn__about-us-area pb-115 go-top">
		<div className="container">
			<div className="row">
				<div className={`col-lg-5`}>
					<div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
						<img src={publicUrl + "assets/img/service/" + el?.img} alt="Image" />
					</div>
				</div>
				<div className={`col-lg-7`}>
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
							<h4 className="font-bold text-2xl text-secondary-color-2">{t(el?.title)}</h4>
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">{el?.price}</h6>
							<div className={`text-secondary-color-2`}>
								{new Array(el?.p).fill(() => 1).map((_, index) => <p key={el?.key + 'p'} className='text-secondary-color-2' hidden={!viewMore && index > 1}>{t(`services.${el?.key}.p` + (index + 1))}</p>)}
								{viewMore ? (<ul>
									{el?.li && <li className='text-secondary-color-2'>{t(`services.${el?.key}.list.title`)}</li>}
									{new Array(el?.li).fill(() => 1).map((_, index) => <li key={el?.key + 'li'} className='text-secondary-color-2'>{t(`services.${el?.key}.list.li` + (index + 1))}</li>)}
								</ul>) : null}
							</div>
							<span className='text-decoration-underline text-primary cursor-pointer' onClick={handleShowMore}>{!viewMore ? <>{t('services.readMore')}</> : <>{t('services.hide')}</>}</span>
						</div>
						<div className="about-us-info-wrap-inner about-us-info-devide---">
						</div>
						<div className="btn-wrapper animated">
							<Link to={
								{
									key: 'ac3df4', // not with HashHistory!
									pathname: `/${el?.type}`,
								}
							} className="theme-btn-1 btn btn-effect-1 text-uppercase">{t('services.viewMore')}</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default AboutV5