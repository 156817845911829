import React, { Component, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { rooms } from '../../mocks/rooms';
import { numberAsEuro, strRandom } from '../../utils/functions';
import { useAuth } from '../../contexts/AuthContextProvider';
import { useLayout } from '../../contexts/LayoutContext';
import { toastErrorMessage, toastSuccessMessage } from '../../utils/functions/toast';
import HomeService from '../../services/HomeService';
import LoadingIcon from '../LoadingIcon';
import { Slideshow } from '../global-components/SlideShow';
import { baseUrlImagesRoom, baseUrlImagesHome } from '../../utils/environment';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
let publicUrl = process.env.PUBLIC_URL + '/'
let mounted = false;
const FeaturedItemV1 = () => {
	const { srooms, setSROoms, mounted, setMounted } = useLayout();
	useEffect(() => {
		HomeService.getCarouselRooms().then(el => setSROoms(el.data)).catch(err => console.log(err));

		return () => {
			setSROoms(null)
		}
	}, [])


	useEffect(() => {
		if (srooms) {
			const $ = window.$;
			$('.ltn__product-slider-item-three-active').slick({
				arrows: true,
				dots: false,
				infinite: true,
				speed: 300,
				slidesToShow: 3,
				slidesToScroll: 1,
				prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
				nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							dots: true,
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 580,
						settings: {
							arrows: false,
							dots: true,
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});

		}


	}, [srooms])


	return <div>
		<div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h1 className="section-title text-secondary-color-2">Últimas habitaciones</h1>
						</div>
					</div>
				</div>

				<div className="row ltn__product-slider-item-three-active justify-content-center slick-arrow-1">
					{srooms ? srooms.map((el, index) => <Card key={el?.id} el={el} publicUrl={publicUrl} />) : <LoadingIcon className='col-1' />}
				</div>
			</div>
		</div>
	</div >
}
export const Card = ({ el,  className = "col-lg-12" }) => {

	const { user, deleteFavoriteRoom, setUser } = useAuth();
	const { markAsFavorite } = useLayout();
	const imgRef = useRef();
	const descriptionRef = useRef();
	const handleOpenImages = () => imgRef.current && imgRef.current.click();

	useEffect(() => {
		if (descriptionRef) {
			descriptionRef.current.innerHTML = el?.type;
		}
	}, [descriptionRef])

	const handleFavorite = e => {
		const contain = user.favorites.find(element => element?.room_id == el?.id);
		if (contain) {
			deleteFavoriteRoom(el?.id)
				.then(response => {
					console.log(response);
					toastSuccessMessage(response?.message);

				}).catch(({ response }) => toastErrorMessage(response?.data?.message))
				.finally(() => {
					setUser(u => ({ ...u, favorites: user.favorites.filter(element => element?.room_id != el?.id) }))
				})
			e.target.classList.remove('text-danger')
		} else {
			markAsFavorite(el?.id, (response) => {
				toastSuccessMessage(response?.message);
				setUser(u => ({ ...u, favorites: [...u?.favorites, { id: strRandom(25), user_id: user?.id, room_id: el?.id }] }))
			}).catch(({ response }) => toastErrorMessage(response?.data?.message))
			e.target.classList.add('text-danger')
		}
	}


	return (<Link to={'#'} /* to={`/product-details/${el?.id}`} */ className={`h-100 ${className}`}>
		<div className="ltn__product-item ltn__product-item-4 text-center---">
			<div className="product-img">
				{(el?.photo && el?.photo?.length) ? <Slideshow style={{ minHeigth: '250px' }} element={el} images={el?.photo.map(element => ({ img: `${element?.url_photo}` }))} /> : null}
				{!el?.photo?.length && <div className='bg-done d-flex align-items-center justify-content-center' style={{ height: '260px' }}>
					<smal>No Image Found</smal>
				</div>}

				<div className="product-badge">
					<ul>
						{user && <li onClick={handleFavorite} className="bg-transparent cursor-pointer favorite-button d-flex align-items-center justify-content-center" style={{ height: '35px', width: '35px' }}><i
							style={{ transition: 'color .2s ease-in-out' }}
							className='fa-solid fa-heart' /></li>}
					</ul>
				</div>
				<div className="product-img-location-gallery">
					<div className="product-img-location go-top">
						<ul>
							<li>
								{/* <span><i className="flaticon-pin" /> {el?.location}</span> */}
							</li>
						</ul>
					</div>
					<div className="product-img-gallery go-top">
						<ul>
							<li>
								<label onClick={handleOpenImages} className='cursor-pointer'><i className="fas fa-camera" /> {el?.photo?.length}</label>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="product-info">
				<div className="product-price">
					<span>{el?.price ? numberAsEuro(el?.price) : 0}<label>/Month</label></span>
				</div>
				<h2 className="product-title go-top text-secondary-color-2 fw-bold truncate text-nowrap"><Link to={`roomview/${el?.code_home}/${el?.code}`}>{el?.title}</Link></h2>
				<div className="hidden product-description text-secondary-color-2">
					<p className='hidden truncate text-nowrap' ref={descriptionRef}> </p>
				</div>
				<ul className="ltn__list-item-2 ltn__list-item-2-before text-secondary-color-2">
					<li><span>{el?.bedrooms}&nbsp;<i className="flaticon-bed" /> {el?.home.count_rooms}</span>

					</li>
					<li><span>{el?.bathrooms}&nbsp;<i className="flaticon-clean" /> {el?.home.count_bathrooms}</span>

					</li>
					<li><span>{el?.squareFt}&nbsp;<i className="fa fa-users" /> {el?.home.count_people}</span>
					</li>
				</ul>
			</div>
		</div>
	</Link>)
}

export default FeaturedItemV1