import axios from "axios";
import { wetherBaseUrl } from "../utils/environment";
import { Service } from "./Service";

class HomeService extends Service {

    getTime = async (access_key, query) => await axios.get(`${wetherBaseUrl}/current`, {
        params: {
            access_key, query
        }
    });

    getZones = async () => await this.client.get('/zones');

    getDates = async () => await this.client.get('/fechas');

    getGenre = async () => await this.client.get('/sex');

    getUniversities = async () => await this.client.get('/universidades');

    search = async (data) => {
        const params = Object.entries(data).map(e => e.join('=')).join('&');
        return await this.client.get(`/search?${params}`);
    }

    markAsFavorite = async (roomId) => await this.client.post('/mark-room-as-favorite', { roomId });

    contact = async (data) => await this.client.post('/contact', data);

    getCarouselRooms = async () => await this.client.get(`/carousel`);

    getAvailableDates = async code => await  this.client.get(`/get-available-dates/${code}`);

}

export default new HomeService();