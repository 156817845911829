import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';
import Title from '../Title';
import { email, phoneNumber } from '../../utils/environment';
import { useTranslation } from 'react-i18next';

const Footer_v1 = () => {

	const { t } = useTranslation();
	useEffect(() => {
		const $ = window.$;

		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);

		$('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

			$(window).scrollTop(0);

			setTimeout(function () {
				$(".quarter-overlay").fadeOut(300);
			}, 800);

		});


		$(document).on('click', '.theme-btn-1 ', function () {
			$('div').removeClass('modal-backdrop');
			$('div').removeClass('show');
			$('div').removeClass('fade');
			$('body').attr("style", "");
		});
	}, [])


	let publicUrl = process.env.PUBLIC_URL + '/'
	let imgattr = "Footer logo"

	return (<footer className="ltn__footer-area  ">
		<div className="footer-top-area  section-bg-2 plr--5">
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-3 col-md-6 col-sm-6 col-12">
						<div className="footer-widget footer-menu-widget clearfix">
						<h4 className="footer-title truncate">helloflatmate</h4>
							{/* <div className="footer-logo">
								  <div className="site-logo">
				                <img src={publicUrl+"assets/img/logo-2.png"} alt="Logo" />
				              </div> 
							<Title />
							</div>*/}
							<p>{t('home.footer.frase')}</p>
							
							<div className="ltn__social-media mt-20">
								<Social />
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-md-6 col-sm-6 col-12">
						<div className="footer-widget footer-menu-widget clearfix">
							<h4 className="footer-title truncate">{t('home.footer.we.title')}</h4>
							<div className="footer-menu go-top">
								<ul>
									<li><Link to="/about">{t('home.footer.we.iam')}</Link></li>
									<li><Link to="faq">{t('home.links.faq')}</Link></li>
									<li><Link to="/contact">{t('home.footer.we.contact')}</Link></li>
									<li><a target='_blank' without={true} rel="noopener noreferrer"href="https://www.helloflatmate.com/site/blog/">Blog</a></li>
								</ul>
							</div>
						</div>
					</div>
				{/* 	<div className="col-xl-3 col-md-6 col-sm-6 col-12">
						<div className="footer-widget footer-menu-widget clearfix">
							<h4 className="footer-title truncate"><span className='truncate w-full text-nowrap'>{t('home.footer.faq.title')}</span></h4>
							<div className="footer-menu go-top">
								<ul>
									<li><Link to="/cart">Hellorooms</Link></li>
									<li><Link to="/wishlist">Helloowners</Link></li>
								</ul>
							</div>
						</div>
					</div> */}
					<div className="col-xl-3 col-md-6 col-sm-6 col-12">
						<div className="footer-widget footer-menu-widget clearfix">
							<h4 className="footer-title truncate text-nowrap">{t('home.footer.medium.title')}</h4>
							<div className="footer-menu go-top">
								<ul>
									<li><a target='_blank' without={true} rel="noopener noreferrer" href="http://www.orientacionvocacional.com/index.php/blogs/item/522-buscarse-la-vida-en-internet">WO</a></li>
									<li><a target='_blank' without={true} rel="noopener noreferrer" href="https://www.diarioinformacion.com/alicante/2012/12/16/residencia-acogera-gratis-estudiantes-erasmus/1325599.html">Informaci&oacute;n</a></li>
									<li><a target='_blank' without={true} rel="noopener noreferrer" href="https://www.lasprovincias.es/v/20140506/economia/valenciano-revoluciona-servicio-atencion-20140506.html">Las provincias</a></li>
									<li><a target='_blank' without={true} rel="noopener noreferrer" href="https://www.diariocritico.com/noticia/447879/sin-clasificar/helloflatmate-para-que-en-tu-llegada-a-valencia-te-sientas-como-en-casa-.html">Diario Cr&iacute;tico</a></li>
								</ul>
							</div>
						</div>
					</div>

					<div className="col-xl-3 col-md-6 col-sm-6 col-12">
						<div className="footer-widget footer-menu-widget clearfix">
							<h4 className="footer-title truncate">{t('home.footer.we.contact')}</h4>
							<div className="footer-menu go-top">
							<div className="footer-address">
								<ul>
									<li>
										<div className="footer-address-icon">
											<i className="icon-placeholder" />
										</div>
										<div className="footer-address-info">
											<p>Valencia, Espa&ntilde;a, Europa</p>
										</div>
									</li>
									<li>
										<div className="footer-address-icon">
											<i className="icon-call" />
										</div>
										<div className="footer-address-info">
											<p><a href={`tel:${phoneNumber.split(' ').join('')}`}>{phoneNumber}</a></p>
										</div>
									</li>
									<li>
										<div className="footer-address-icon">
											<i className="icon-mail" />
										</div>
										<div className="footer-address-info">
											<p><a href={`mailto:${email}`}>{email}</a></p>
										</div>
									</li>
								</ul>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Copyright />
	</footer>
	)
}



export default Footer_v1