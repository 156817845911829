import React, { useEffect, useRef } from 'react';
import { appName, baseUrlImagesRoom, stateName } from '../../utils/environment';
import { useHome } from '../../contexts/HomeContextProvider';
import { sliders } from '../../mocks/home';
import { useLayout } from '../../contexts/LayoutContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { useTranslation } from 'react-i18next';
import { strRandom, toastAction } from '../../utils/functions';
import Search from '../global-components/Search';

let counter = 0;
const BannerV7 = () => {

	const pref = useRef();
	const { t } = useTranslation();
	const handleAnimationIteration = e => {
		counter++;
		if (counter % 2 === 0) {
			const text = pref.current.querySelector('span.text')
			text.textContent = text.textContent === stateName ? appName : stateName;
		}

	}



	return (<div className='container'>

		<div className='row m-0'>
			<div className="col-12 col-md-4">
				<p className="text-color-white--- mb-30 fs-3 mb-0 text-secondary-color-2 px-2">
					<span className="text-secondary-color-2 h3">{
						t('home.welcome')}
						&nbsp;
						{t('home.to')}
					</span>
					&nbsp;<span className='text-wrapper' ref={pref}><span className='text text-secondary-color-2'>{appName}</span>&nbsp;&nbsp;<span className="cursor" onAnimationIteration={handleAnimationIteration}>&#160;</span>
					</span>
				</p>
				<div>
					<div className="ltn__car-dealer-form-tab">
						<div className="tab-content pb-10">
							<div className="tab-pane fade active show" id="ltn__form_tab_1_1">
								<div className="car-dealer-form-inner">
									<Search searchButton />
								</div>
							</div>
							<div className="tab-pane fade" id="ltn__form_tab_1_2">
								<div className="car-dealer-form-inner">

								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div className="offset-1 col-7 d-none d-md-block">
				<video autoPlay={true} loop muted height={'550px'}  width={'100%'}>
				{/* <source src={`https://www.helloflatmate.com/uploads/video/HFM-FinalCortado.mp4`} type="video/mp4" /> */}
				</video>
			</div>
		</div >
	</div>)
}


export const SliderItem = ({ el, room }) => {

	return (<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image"
		data-bs-bg={el?.filename ? `${el?.url_photo}` : el?.img} style={{ maxHeight: '200px', height: 'fit-content', borderRadius: '15px !important' }}>
		<div className="ltn__slide-item-inner text-center">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 align-self-center">
						<div className="slide-item-info">
							<div className="slide-item-info-inner ltn__slide-animation">
								<div className="slide-video mb-50 d-none">

								</div>
								<h6 className="slide-sub-title white-color--- animated"> </h6>
								<h1 className="slide-title animated "></h1>
								<div className="slide-brief animated">

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>)
}


export default BannerV7