import React, { Component, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../contexts/LayoutContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toastAction } from '../../utils/functions';
import { types } from '../../utils/navigation';
import useWidth from '../../hooks/useWidth';

const SearchForm = () => {
	const { t } = useTranslation();
	const { setRooms, dates, searchFormStatus, zones, universities, search, setSearchFormStatus, resetParams } = useLayout();
	const history = useHistory();
	const zoneRef = useRef();
	const dateRef = useRef();
	const typeRef = useRef();
	const universitesRef = useRef();
	const { width, breakpoints } = useWidth()

	const handleSubmit = async e => {
		e.preventDefault();
		const zone = zoneRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const type = typeRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const date = dateRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const univ = universitesRef.current.querySelector('li.selected').getAttribute('data-value') || null;

		const data = {};
		if (!isNaN(zone)) {
			Object.assign(data, { zone })
		}
		if (!isNaN(type)) {
			Object.assign(data, { type })
		}
		if (!isNaN(date)) {
			Object.assign(data, { date })
		}
		if (!isNaN(univ)) {
			Object.assign(data, { univ })
		}

		toastAction(search(data), 'Cargando...', () => setSearchFormStatus(false)).then(response => {
			setRooms(response?.data);
			if (response?.data) {
				const params = Object.entries(data).map(e => e.join('=')).join('&');
				if (data?.type && (types?.helloRoom == data?.type || types?.helloLandlord == data?.type)) {
					history.push(`/roomsearch?${params}`);
				} else {
					history.push(`/flatsearch?${params}`);
				}
			}
		}).catch(el => console.log(el));
	}

	useEffect(() => {
		//Zones
		if (zones) {
			const ul = zoneRef.current.querySelector('ul.list')
			if (ul?.childElementCount === 1) {
				ul.innerHTML += zones.reduce((prev, current) => prev + `<li data-value="${current?.id}" class="option">${current?.zone}<div></div></li>`, '');;
			}
		}
		//Dates
		if (dates) {
			const ul = dateRef.current.querySelector('ul.list')
			if (ul?.childElementCount === 1) {
				ul.innerHTML += dates.reduce((prev, current) => prev + `<li data-value="${current?.id}" class="option">${current?.name}<div></div></li>`, '');;
			}
		}
		//Universities
		if (universities) {
			const ul = universitesRef.current.querySelector('ul.list')
			if (ul?.childElementCount === 1) {
				ul.innerHTML += universities.reduce((prev, current) => prev + `<li data-value="${current?.id}" class="option">${current?.university}<div></div></li>`, '');;
			}
		}

	}, [zones, dates, universities])

	useEffect(() => {


		return () => {
			resetParams && resetParams();
		}

	}, [])

	return (
		<div className="ltn__car-dealer-form-area mt--65 mt-120 pb-115--- custom-search-form" style={{
			transform: width > breakpoints.lg ? "translateY(-350px)" : "translateY(0px)"
		}}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__car-dealer-form-tab">
							<div className="ltn__tab-menu  text-uppercase d-none">
								<div className="nav">
									<a className="active show" data-bs-toggle="tab" href="#ltn__form_tab_1_1"><i className="fas fa-car" />Find A Car</a>
									<a data-bs-toggle="tab" href="#ltn__form_tab_1_2" ><i className="far fa-user" />Get a Dealer</a>
								</div>
							</div>
							<div className="tab-content bg-white box-shadow-1 position-relative pb-10">
								<div className="tab-pane fade active show" id="ltn__form_tab_1_1">
									<div className="car-dealer-form-inner">
										<form action="#" className="ltn__car-dealer-form-box row" onSubmit={handleSubmit}>
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- col-lg-3 col-md-6" ref={typeRef}>
												<select className="nice-select">
													<option>{t('home.accomodationType.title')}</option>
													<option value={2}>helloRoom</option>
													<option value={3}>helloColiving</option>
													<option value={4}>helloStudio</option>
													<option value={5}>helloLandlord</option>
												</select>
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-meter---- col-lg-3 col-md-6" ref={zoneRef}>
												<select className="nice-select">
													<option>{t('home.zone')}</option>
												</select>
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-calendar---- col-lg-3 col-md-6" ref={dateRef}>
												<select className="nice-select">
													<option>{t('home.date')}</option>
												</select>
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-calendar---- col-lg-3 col-md-6" ref={universitesRef}>
												<select className="nice-select">
													<option>{t('home.university')}</option>
												</select>
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
												<div className="btn-wrapper text-center mt-0 go-top w-100">
													<button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase truncate text-nowrap w-100">{t('home.search')}</button>
												</div>
											</div>
										</form>
									</div>
								</div>
								<div className="tab-pane fade" id="ltn__form_tab_1_2">
									<div className="car-dealer-form-inner">
										<form action="#" className="ltn__car-dealer-form-box row">
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- col-lg-3 col-md-6">
												<select className="nice-select">
													<option>Choose Area</option>
													<option>chicago</option>
													<option>London</option>
													<option>Los Angeles</option>
													<option>New York</option>
													<option>New Jersey</option>
												</select>
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-meter---- col-lg-3 col-md-6">
												<select className="nice-select">
													<option>Property Status</option>
													<option>Open house</option>
													<option>Rent</option>
													<option>Sale</option>
													<option>Sold</option>
												</select>
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-calendar---- col-lg-3 col-md-6">
												<select className="nice-select">
													<option>Property Type</option>
													<option>Apartment</option>
													<option>Co-op</option>
													<option>Condo</option>
													<option>Single Family Home</option>
												</select>
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
												<div className="btn-wrapper text-center mt-0 go-top">
													{/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
													<Link to="/go-top" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search Properties</Link>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>)
}

export default SearchForm