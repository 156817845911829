import React from "react";

const DinamicSliderItem = ({ url }) => {

    return (<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3"
        style={{ borderRadius: '15px !important', height: '450px' }} data-bs-bg={url}>
        <img src={url} height={'450px'}
        style={{objectFit:'cover'}}
        className="position-absolute top-0 w-100 h-100" />
        <div className="ltn__slide-item-inner text-center">

        </div>
    </div>)
}

export default DinamicSliderItem;