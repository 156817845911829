import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import Room from "./Room"
import { useAuth } from "../../../../contexts/AuthContextProvider";
import { getCustomDate, getFormatedDate, getForm, numberAsEuro, toastAction } from "../../../../utils/functions";
import { baseUrlImagesRoom } from "../../../../utils/environment";
import { useTranslation } from "react-i18next";
let publicUrl = process.env.PUBLIC_URL + '/'

export default function Bills({ bills, setBills }) {




    return (
        <div className="tab-pane fade" id="ltn_tab_1_10">
            <div className="ltn__myaccount-tab-content-inner">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__myaccount-tab-content-inner">
                            <div className="ltn__my-properties-table table-responsive">
                                <table className="table">
                                    {bills && (<thead>
                                        <tr>
                                            <th scope="col">Detalles</th>
                                            <th scope="col" />
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                            <th scope="col">Opciones</th>
                                        </tr>
                                    </thead>)}
                                    <tbody>
                                        {
                                            bills ? (<>
                                                {
                                                    bills?.data?.length ? bills?.data?.map((el, index) => {

                                                        return <Bill el={el} key={`bill-${index}`} />;
                                                    })
                                                        : <tr><td colSpan={5}><h1 className="text-secondary-color-2 fw-bold">No cuenta con facturas disponibles</h1></td></tr>
                                                }
                                            </>) : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {
                                bills && bills?.data?.length ? (<div className="ltn__pagination-area text-center">
                                    <div className="ltn__pagination">
                                        <ul>
                                            <li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
                                            {
                                                new Array(bills?.last_page).map((el, index) => <li key={`page-${index}`} className={bills?.current_page === index ? 'active' : ''}><Link to="#">{index}</Link></li>)
                                            }
                                            <li><Link to="#" disabled={bills?.current_page === bills?.last_page} ><i className="fas fa-angle-double-right" /></Link></li>
                                        </ul>
                                    </div>
                                </div>) : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export const Bill = ({ el, handleAction }) => {
    const { t } = useTranslation();
    const { payPendingBill } = useAuth();
    const formRef = useRef();

    const handlePayPendingOrder = (key) => {
        toastAction(payPendingBill({ key })).then(res => {
            const form = getForm(res.data, formRef.current, 'POST');
            form.submit();
        }).catch(err => console.log(err));
    }

    return <>

        {
            el?.payments?.sort((current, prev) => current?.status - prev?.status).map(payment => {
                return (<tr>

                    <td className="ltn__my-properties-img go-top">
                        <form ref={formRef} />
                        <Link to={`/roomview/${payment.reserved?.room?.code_home}/${payment.reserved?.room?.code}`}><img src={`${payment?.reserved?.room?.photo[0]?.url_photo}`} alt="#" /></Link>
                    </td>
                    <td>
                        <div className="ltn__my-properties-info">
                            <h6 className="mb-10 go-top"><Link to={`/roomview/${payment.reserved?.room?.code_home}/${payment.reserved?.room?.code}`}>{payment.reserved?.room?.code_home}/{payment.reserved?.room?.code}</Link></h6>
                            <small><i className="icon-placeholder" /> Valencia, {t('topbar.country')}</small>
                            <div className="product-ratting">
                                <ul className="d-flex" style={{ gap: '5px' }}>
                                    <li className="review-total text-secondary-color-2 px-2"> <a href="#">{numberAsEuro(payment?.sale)}</a></li>
                                    <li className="review-total text-secondary-color-2 px-2"> <a href="#"></a></li>
                                </ul>
                            </div>
                        </div>
                    </td>
                    <td>
                        <p className="mb-0 text-secondary-color-2 fw-bold">
                            {getCustomDate(payment?.date)}
                            {/*      {payment?.date}  */}
                        </p>
                        {payment.status == 2 ? <p className="text-done px-1 mb-0 text-center">Pagada</p> : <p onClick={() => handlePayPendingOrder(payment?.bill)} className="bg-done p-1 mb-0 text-center cursor-pointer" style={{ borderRadius: '5px' }}>Pagar</p>}

                    </td>
                    <td></td>
                    <td>
                        {handleAction && <a onClick={handleAction} tp="#" className='m-auto w-fit cursor-pointer'><i className="fa-solid fa-trash-can" /></a>}
                        {payment.status == 2 && <a href={`${payment?.download_link}`} target="_blank" rel="noopener noreferrer" className='m-auto w-fit cursor-pointer  text-done'><i className="fa fa-download" /></a>}
                    </td>
                </tr>)
            })
        }
    </>
}