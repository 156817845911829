import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getFormatedDate, numberAsEuro } from "../../../../utils/functions";
import { baseUrlImagesRoom } from "../../../../utils/environment";
let publicUrl = process.env.PUBLIC_URL + '/'

const Room = ({ el, setRooms, handleAction, handleSecondaryAction }) => {
    const { t } = useTranslation();
    return (<tr>
        <td className="ltn__my-properties-img go-top">
            <Link to={`/roomview/${el?.code_home}/${el?.code}`}><img src={el?.photo?.length ? el?.photo[0]?.url_photo : ''} alt="#" /></Link>
        </td>
        <td>
            <div className="ltn__my-properties-info">
                <h6 className="mb-10 go-top"><Link to={`/roomview/${el?.code_home}/${el?.code}`}>{el?.title}</Link></h6>
                <small><i className="icon-placeholder" /> Valencia, {t('topbar.country')}</small>
                <div className="product-ratting">
                    <ul>
                        <li className="review-total"> <a href="#">{numberAsEuro(el?.price)}</a></li>
                    </ul>
                </div>
            </div>
        </td>
        <td>{getFormatedDate(el?.created_at)}</td>
        <td></td>
        <td>{handleAction && <a onClick={handleAction} tp="#" className='m-auto w-fit cursor-pointer'><i className="fa-solid fa-trash-can" /></a>}</td>
    </tr>)
}
export default Room;