import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { toastAction } from '../../utils/functions';
import HomeService from '../../services/HomeService';
import { useTranslation } from 'react-i18next';
import { contactSubjet, contactRole } from '../../utils/navigation';



const ContactForm = () => {
	const contactRef = useRef()
	const { t } = useTranslation();
	const handleSubmit = e => {
		e.preventDefault();
		const subject_contact = contactRef.current.querySelector('#subject li.selected').getAttribute('data-value') || null;
		const role_contact = contactRef.current.querySelector('#role li.selected').getAttribute('data-value') || null;
		const body = Object.fromEntries(new FormData(e.target).entries());
		console.log({ ...body, role_contact, subject_contact });
		toastAction(HomeService.contact({ ...body, role_contact, subject_contact }), 'Cargando...').catch(err => console.log(err));
	}



	return <div className="ltn__contact-message-area mb-120">
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="ltn__form-box contact-form-box box-shadow white-bg">
						<h4 className="title-2">Contáctanos</h4>
						<form id="contact-form" onSubmit={handleSubmit} method="post">

							<div className="row" ref={contactRef}>
								<div className="col-md-6">
									<div className="input-item input-item-name ltn__custom-icon">
										<input type="text" required name="name_contact" placeholder="Nombre" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="input-item input-item-email ltn__custom-icon">
										<input type="email" required name="email_contact" placeholder="Email" />
									</div>
								</div>

								<div className="col-md-6">
									<div className="input-item input-item-subject ltn__custom-icon" id='subject'>
										<select className="">
											{contactSubjet.map(el => <option key={el} value={el}>
												{t(el)}
											</option>)}
										</select>
									</div>
								</div>
								<div className="col-md-6">
									<div className="input-item input-item-subject ltn__custom-icon" id='role'>
										<select className="" required>
											{/* 			<option>{t('contact.role.title')}</option> */}
											{contactRole.map(el => <option key={el} value={el}>{t(el)}</option>)}
										</select>
									</div>
								</div>
							</div>
							<div className="input-item input-item-textarea ltn__custom-icon">
								<textarea name="comments_contact" required placeholder="Descripci&oacute;n" defaultValue={""} />
							</div>
							<div className="btn-wrapper mt-0">
								<button className="btn theme-btn-1 btn-effect-1" type="submit">Enviar Mensaje</button>
							</div>
							<p className="form-messege mb-0 mt-20" />
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default ContactForm