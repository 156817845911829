const es = {
    translation: {
        contact: {
            subject: {
                title: 'Asunto',
                need: 'Necesito alojamiento pero no encuentro fechas',
                property: 'Soy propietario de una vivienda',
                colaborator: 'Soy colaborador',
                other: 'Otros',
            },
            role: {
                title: 'Rol',
                student: 'Estudiante',
                property: 'Propietario',
                nomada: 'Nómada',
                other: 'Otro',
            }
        },
        account: {
            active: 'activar',
            account: 'cuenta',
            my: 'mi',
            title: 'Mi cuenta',
            has_account: 'Accede a tu cuenta',
            no_account: 'Crea tu cuenta ahora',
            init: 'Iniciar Sesión',
            reg: 'Registro',
            active_account: 'Activar Cuenta',
            links: {
                dashboard: 'Tablero',
                profile: 'Perfil',
                favorite: 'Favoritos',
                pendingReserved: 'Reservas Pendientes',
                bills: 'Pagos',
                changePassword: 'Cambio de Contraseña',
            },
            profile: {
                profile: 'Mi Perfil',
                name: 'Nombre',
                lastname: 'Apellidos',
                email: 'Correo',
                dni: 'DNI/Pasaporte',
                address: 'Dirección',
                phone_number: 'Número telefónico',
                postal_code: 'Código Postal',
                city: 'Ciudad',
                state: 'Estado',
                country: 'País',
            },
            dashboard: {
                welcome: 'Hola',
                text: 'Desde el panel de su cuenta puede ver sus pedidos recientes, administrar sus direcciones de envío y facturación y editar su contraseña y los detalles de su cuenta.'
            },
            changePassword: {
                title: 'Cambio de Contraseña',
                currentPassword: 'Contraseña Actual',
                password: 'Contraseña Nueva',
                confirmPassword: 'Confirmar Contraseña'
            }
        },
        generals: {
            saveChanges: 'Guardar Cambios',
            active: 'Activar',
            viewRoom: 'Ver habitaciones',
            goToHome: 'Volver a inicio',
        },
        topbar: {
            spanish: 'Español',
            english: 'Inglés',
            country: 'España'
        },
        home: {
            welcome: 'Bienvenido',
            to: 'a',
            accomodationType: {
                title: 'Tipo de alojamiento',
                value1: 'Rooms',
                value2: 'Coliving'
            },
            zone: '¿En que zona?',
            date: '¿En que fecha?',
            university: 'Universidad',
            links: {
                home: 'Inicio',
                know: 'Conócenos',
                service: 'Servicios',
                contact: 'Contacta',
                blog: 'Blog',
                faq: 'FAQS',
                login: 'Iniciar Sesión',
                register: 'Registro',
                logout: 'Cerrar Sessión',
                admin: 'Administración',
                myAccount: 'Mi Cuenta',
            },
            text: 'Encuentra tu alojamiento ideal.',
            search: 'Buscar',
            mainservices: 'Nuestros Servicios Principales',
            servicestitle: 'Nuestros Servicios',
            alojamiento: 'Alojamientos en zonas consolidadas.',
            alojamientotxt: 'En helloflatmate hacemos de filtro previa a su búsqueda, por ello todas las viviendas que gestionamos se encuentran en zonas consolidadas y seguras en Valencia y Moncada.',
            contrato: 'Contrato.',
            contratotxt: 'Es crucial contar con un contrato de alquiler para una residencia legal. Todos nuestros alojamientos siguen un modelo contractual basado en el Código Civil, equiparando derechos y responsabilidades para arrendadores y arrendatarios.',
            atencion: 'Atención a flatmates.',
            atenciontxt: 'La atención a flatmates es de 9 a 17 horas de lunes a viernes. Emergencias 24/7: Perfecto para cuando se te han olvidado las llaves en casa fuera de horario.',
            habitacion: 'Alojamientos preparados.',
            habitaciontxt: 'Nos encargamos de que los alojamientos estén limpios y revisados para el día de inicio de contrato y así disfrutes de una bienvenida cómoda.',
            mantenimiento: 'Mantenimientos propios.',
            mantenimientotxt: 'Las alojamientos con contrato de 5 meses se pintan y revisan dos veces al año; en pisos con contratos de 10 y 11 meses, esta revisión se realiza una vez al año. Si surge algún problema durante tu estancia, contáctanos por WhatsApp durante el horario laboral para coordinar asistencia técnica inmediata.',
            suministros: 'Alta de suministros',
            suministrostxt: 'Todos nuestros alojamientos a excepción de hello studios, vienen con altas de luz, agua e internet wifi.',
            more: '¡MUCHO MÁS QUE UNA HABITACIÓN!',
            moretxt: 'Nos especializamos en gestionar estancias a estudiantes y nómadas digitales en viviendas que dirigimos en Valencia y Moncada.',
            moretxt1: 'Nos aseguramos de que nuestras viviendas estén ubicadas en áreas consolidadas, ofreciendo alojamientos con contrato, amueblados con altas de suministros, limpia y revisada por nuestro personal de mantenimiento.',
            footer: {
                frase: 'Desde 2011 colaborando en la creación de alojamientos dignos para estudiantes y nómadas digitales.',
                we: {
                    title: 'Enlaces',
                    iam: '¿Quiénes somos?',
                    location: '¿Donde encontrarnos?',
                    contact: 'Contacto',
                    video: 'Mira este video',
                },
                faq: {
                    title: 'Preguntas Frecuentes',
                    iam: '¿Quienes somos?',
                    location: '¿Donde encontrarnos?',
                    contact: 'Contacto',
                    video: 'Mira este video',
                },
                medium: {
                    title: 'Medios de comunicación',
                },
                terms: 'Terminos & Condiciones',
                cookies: 'Cookies',
                legalWarning: 'Aviso legal',
                copyrigth: 'Todos los derechos reservados'
            }
        },
        services: {
            readMore: 'Leer más',
            viewMore: 'Ver más',
            hide: 'Contraer',
            headerTitle: '¿Que ofrecemos?',
            subheader: 'Servicios',
            hellorooms: {
                realTitle: 'helloRooms',
                title: 'Habitaciones preparadas para entrar a Vivir.',
                list: {
                    title: 'Además, ofrecemos garantías a los propietarios de las viviendas para que acepten:',
                    li1: 'Los suministros a nombre de la propiedad.',
                    li2: 'Contratos flexibles de 5, 10 o 11 meses, según la vivienda.',
                    li3: 'Una fianza de tan solo 300€.',
                    li4: 'Contratos sin necesidad de avales.'
                },
                p1: 'Nuestras acogedoras hello rooms están diseñadas específicamente para estudiantes internacionales y nacionales que buscan un espacio listo para habitar desde el primer momento dado que vienen a Valencia por menos de un año y son gestionadas por los agentes de helloflatmate.',
                p2: 'Ya que los propietarios no están legalmente obligados a entregar las viviendas limpias y revisadas, ofrecemos este servicio de preparación para aquellos que prefieren despreocuparse de las tareas iniciales.',
                p3: 'Incluye todos los servicios dados de alta, así como una conexión a Internet, y proporciona la oportunidad de convivir con compañeros de piso de edades y estilos de vida similares.',
                p4: 'Este producto, diseñado específicamente para satisfacer unas necesidades muy concretas de un público muy específico tiene un coste de honorarios de gestión de 459,80 euros, IVA incluido. '
            },
            landlord: {
                realTitle: 'helloLanlord',
                title: '',
                list: {
                    title: 'Además, ofrecemos garantías a los propietarios de las viviendas para que acepten:',
                    li1: 'Los suministros a nombre de la propiedad.',
                    li2: 'Contratos flexibles de 5, 10 o 11 meses, según la vivienda.',
                    li3: 'Una fianza de tan solo 300€.',
                    li4: 'Contratos sin necesidad de avales.'
                },
                p1: 'hello landlord son habitaciones gestionadas por propietarios de nuestra confianza y están diseñadas específicamente para estudiantes internacionales y nacionales que buscan un espacio listo para habitar desde el primer momento dado que vienen a Valencia por menos de un año.',
                p2: 'Ya que los propietarios no están legalmente obligados a entregar las viviendas limpias y revisadas, ofrecemos este servicio de preparación en esta modalidad para aquellos que prefieren despreocuparse de las tareas iniciales.',
                p3: 'Incluye todos los servicios dados de alta, así como una conexión a Internet, y proporciona la oportunidad de convivir con compañeros de piso de edades y estilos de vida similares.',
                p4: 'Este producto, diseñado específicamente para satisfacer unas necesidades muy concretas de un público muy específico tiene un coste de honorarios de gestión de 459,80 euros, IVA incluido. '
            },
            studio: {
                realTitle: 'hellostudio',
                title: '',
                list: {

                },
                p1: 'Si vienes a Valencia a estudiar toda la carrera y quieres la máxima independencia, hello studios es para tí.',
                p2: 'En el caso de viviendas con contratos de alquiler de larga estancia (mínimo 5 años) regulados por la Ley de Arrendamientos Urbanos (LAU) y según la Ley de Vivienda 12/23. Establece que el arrendatario estará exento de abonar una comisión por los servicios de intermediación entre el propietario y el inquilino. ',
                p3: 'Esta legislación se ha diseñado con el propósito de facilitar el acceso a la vivienda  a jóvenes en edad laboral, permitiéndoles emanciparse con mayor facilidad y hacer frente a los demás costes asociados al alquiler, como el mobiliario, los servicios de agua, electricidad y gas, así como las dos mensualidades de fianza que pueden ser requeridas.',
                p4: 'En nuestra cartera, encontrarás apartamentos que varían desde semi-amueblados y sin amueblar, algunos requieren alta de suministros y otros no (luz, agua y gas).'
            },
            coliving: {
                realTitle: 'helloColiving',
                title: '',
                list: {
                    title: 'Además, ofrecemos garantías a los propietarios de las viviendas para que acepten:',
                    li1: 'Los suministros a nombre de la propiedad.',
                    li2: 'Contratos flexibles de 5, 10 o 11 meses, según la vivienda.',
                    li3: 'Una fianza de tan solo 300€.',
                    li4: 'Contratos sin necesidad de avales.'
                },
                p1: 'Nuestros acogedores y modernos alojamientos hello coliving están diseñados específicamente para nómadas digitales que buscan un espacio listo para habitar desde el primer momento.',
                p2: 'En el contexto del coliving, donde valoramos la colaboración y la comunidad, ofrecemos servicios adicionales para una experiencia más completa. Además de todos los servicios de atención a huéspedes de helloflatmate, proporcionamos limpieza quincenal de la habitación, cambio de sábanas y suministros de hasta 50 euros por mes por persona.',
                p3: 'Incluye todos los servicios activados, así como una conexión a Internet, y brinda la oportunidad de convivir con compañeros de coliving de edades y estilos de vida similares.',
                p4: 'Este producto, diseñado específicamente para satisfacer las necesidades de una comunidad de coliving, tiene un coste de honorarios de gestión de 459,80 euros, IVA incluido.'
            },
        },
        payments: {
            ok: 'Pago procesado satisfactoriamente',
            ko: 'Pago pendiente de procesar',
            index: 'Estado del pago'
        },
        result: {
            search: 'Resultados de la búsqueda'
        }

    }
};

export default es;