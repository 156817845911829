import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContextProvider';
import ChangePassword from './partials/my-account/changePassword';
import Dashboard from './partials/my-account/dashboard';
import Profile from './partials/my-account/profile';
import Favorites from './partials/my-account/favorites';
import Payments from './partials/my-account/payment';
import { useTranslation } from 'react-i18next';
import { toastAction } from '../../utils/functions';
import { toastErrorMessage } from '../../utils/functions/toast';
import Bills from './partials/my-account/Bills';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from '../../hooks/useQuery';

let publicUrl = process.env.PUBLIC_URL + '/'

const sections = {
	bills: 'ltn_tab_1_10'
}

const MyAccount = () => {
	const { t } = useTranslation()
	const { getMyForiteRooms, getMyPendingRooms, getMyBills } = useAuth();
	const [rooms, setRooms] = useState(null)
	const [pendingRooms, setPendingRooms] = useState(null)
	const [bills, setBills] = useState(null)
	const params = useQuery();
	const linkRef = useRef();

	const handleLoadFavoriteRooms = () => {
		toastAction(getMyForiteRooms()).then(response => {
			setRooms(response?.data)
		}).catch(({ response }) => toastErrorMessage(response?.data?.message));
	}

	const handleLoadPendingRooms = () => {
		toastAction(getMyPendingRooms()).then(response => {
			setPendingRooms(response?.data)
		}).catch(({ response }) => toastErrorMessage(response?.data?.message));
	}

	const handleLoadBills = () => {
		toastAction(getMyBills()).then(response => {
			setBills(response?.data)
		}).catch(({ response }) => toastErrorMessage(response?.data?.message));
	}

	const { handleLogout } = useAuth();

	useEffect(() => {
		const section = params.get('section');
		if (linkRef && sections[section]) {
			const link = linkRef.current.querySelector(`a[href="#${sections[section]}"]`);
			link && link.click();
		}

		//section && sections[section] && 
	}, [])

	return (<div className="liton__wishlist-area pb-70">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 px-0">
					{/* PRODUCT TAB AREA START */}
					<div className="ltn__product-tab-area">
						<div className="">
							<div className="row mx-0">
								<div className="col-lg-4 px-md-3">
									<div className="ltn__tab-menu-list mb-50">
										<div className="nav me-0" ref={linkRef}>
											<a className="active show" data-bs-toggle="tab" href="#ltn_tab_1_1">{t('account.links.dashboard')} <i className="fas fa-home" /></a>
											<a data-bs-toggle="tab" href="#ltn_tab_1_2">{t('account.links.profile')} <i className="fas fa-user" /></a>
											<a data-bs-toggle="tab" onClick={handleLoadFavoriteRooms} href="#ltn_tab_1_6">{t('account.links.favorite')} <i className="fa-solid fa-heart" /></a>
											<a data-bs-toggle="tab" href="#ltn_tab_1_8" onClick={handleLoadPendingRooms}>{t('account.links.pendingReserved')} <i className="fa-solid fa-money-check-dollar" /></a>
											<a data-bs-toggle="tab" href="#ltn_tab_1_10" onClick={handleLoadBills}>{t('account.links.bills')} <i className="fa-solid fa-money-check-dollar" /></a>
											<a data-bs-toggle="tab" href="#ltn_tab_1_9">{t('account.links.changePassword')} <i className="fa-solid fa-lock" /></a>
											<a className='cursor-pointer' onClick={handleLogout}> {t('home.links.logout')}<i className="fas fa-sign-out-alt" /></a>
										</div>
									</div>
								</div>
								<div className="col-lg-8">
									<div className="tab-content">
										<Dashboard />
										<Profile />
										<Favorites rooms={rooms} setRooms={setRooms} />
										<Payments rooms={pendingRooms} setRooms={setPendingRooms} />
										<Bills bills={bills} setRooms={setBills} />
										<ChangePassword />
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* PRODUCT TAB AREA END */}
				</div>
			</div>
		</div>
	</div>)
}

export default MyAccount