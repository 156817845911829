import React, { Component } from 'react';
import { Link } from 'react-router-dom';


const TermsV1 = () => (<div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
	<div className="container">
		<div className="row">
			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Derecho de información</h1>
				</div>
				<p className='text-secondary-color-2'>
					A los efectos de lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR), relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y a la Ley Orgánica 3/2018 de 5 de diciembre, de protección de datos de carácter personal y garantía de los derechos digitales (LOPDGDD), se informa al usuario de la existencia de un tratamiento automatizado de datos de carácter personal creado por HELLO FLAT MATE, S.L. y bajo su responsabilidad con los datos personales que nos pudiera facilitar durante la navegación por nuestro Sitio Web ya bien sea en el momento de su registro o cuando remita un mensaje de correo electrónico.
				</p>
			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Responsable de tratamiento:</h1>
				</div>
				<ul className='text-secondary-color-2'>
					<li>Denominación social: HELLO FLAT MATE, S.L.</li>
					<li>CIF: B98358963</li>
					<li>Domicilio social: C/ CAMPOAMOR 8 1, 46021 - VALENCIA (VALENCIA)</li>
					<li>Teléfono: 601 158 261</li>
					<li>Correo electrónico a efectos de comunicación: rooms@helloflatmate.com</li>
				</ul>
			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Finalidad del tratamiento:</h1>
				</div>
				<p className='text-secondary-color-2'>
					De acuerdo a lo establecido en la legislación vigente en materia de Protección de Datos de Carácter Personal, Reglamento 2016/679 General de Protección de Datos (RGPD) y la Ley Orgánica 3/2018 de 5 de diciembre, de protección de datos de carácter personal y garantía de los derechos digitales (LOPDGDD), le indicamos que la finalidad del tratamiento de sus datos es la descrita en cada uno de los formularios establecidos en la página web en los que usted nos facilita sus datos.
				</p>
			</div>



			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Legitimación:</h1>
				</div>
				<p className='text-secondary-color-2'>
					Artículo 6.1.a) del RGPD. El interesado dio su consentimiento para el tratamiento de sus datos personales para uno o varios fines específicos.</p>
			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Conservación y tratamiento de los datos:</h1>
				</div>
				<p className='text-secondary-color-2'>
					Sus datos serán conservados durante el tiempo necesario para atender su solicitud, siempre y cuando no nos indique lo contrario ejerciendo su derecho de supresión, o durante el tiempo necesario para cumplir con las obligaciones legales y atender las posibles responsabilidades que pudieran derivar del cumplimiento de la finalidad para la que los datos fueron recabados. En caso de ser necesario, HELLO FLAT MATE, S.L. cancelará o rectificará los datos cuando resulten inexactos, incompletos o hayan dejado de ser necesarios para su finalidad, de conformidad con lo previsto en la legislación vigente en materia de Protección de Datos de Carácter Personal.
				</p>
				<p className='text-secondary-color-2'>
					Mientras no nos comunique lo contrario, entenderemos que sus datos no han sido modificados, que usted se compromete a notificarnos cualquier variación y que tenemos el consentimiento para utilizarlos a fin de poder fidelizar la relación entre las partes.
				</p>
				<p className='text-secondary-color-2'>
					Los datos facilitados no serán cedidos a terceros salvo en los casos en que exista una obligación legal.</p>
				<p className='text-secondary-color-2'>
					HELLO FLAT MATE, S.L. no elaborará ningún tipo de “perfil”, en base a la información facilitada. No se tomarán decisiones automatizadas en base a perfiles. </p>
				<p className='text-secondary-color-2'>
					De acuerdo con lo que establece la Ley 34/2002, de Servicios de la Sociedad de la Información y el Comercio Electrónico en su artículo 21, solicitaremos su consentimiento para poder realizar comunicaciones publicitarias que consideremos puedan ser de su interés, por correo electrónico o por cualquier otro medio de comunicación electrónica equivalente.
				</p>
				<p className='text-secondary-color-2'>
					Usted tiene derecho a oponerse y/o retirar el consentimiento al tratamiento de los datos indicados anteriormente, en su caso, y en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada, mediante notificación al responsable del tratamiento en la/s dirección/es indicada/s.	</p>
			</div>

			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Medidas técnicas y organizativas</h1>
				</div>
				<p className='text-secondary-color-2'>
					HELLO FLAT MATE, S.L. adopta las medidas técnicas, controles y procedimientos de carácter físico, organizativo y tecnológico, razonablemente fiables y efectivos, orientados a preservar la integridad y la seguridad de sus datos y garantizar su privacidad. No obstante, no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que terceros puedan causar en los sistemas informáticos, documentos electrónicos o ficheros del usuario.
				</p>
				<p className='text-secondary-color-2'>
					Además, todo el personal con acceso a los datos personales ha sido formado y tiene conocimiento de sus obligaciones con relación a los tratamientos de datos personales.</p>

				<p className='text-secondary-color-2'>
					En el caso de los contratos que suscribimos con nuestros proveedores incluimos cláusulas en las que se les exige mantener el deber de secreto respecto a los datos de carácter personal a los que hayan tenido acceso en virtud del encargo realizado, así como implantar las medidas de seguridad técnicas y organizativas necesarias para garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanentes de los sistemas y servicios de tratamiento de los datos personales.
				</p>




				<p className='text-secondary-color-2'>
					Todas estas medidas de seguridad son revisadas de forma periódica para garantizar su adecuación y efectividad.</p>
				<p className='text-secondary-color-2'>
					Sin embargo, la seguridad absoluta no se puede garantizar y no existe ningún sistema de seguridad que sea impenetrable por lo que, en el caso de cualquier información objeto de tratamiento y bajo nuestro control se viese comprometida como consecuencia de una brecha de seguridad, tomaremos las medidas adecuadas para investigar el incidente, notificarlo a la Autoridad de Control y, en su caso, a aquellos usuarios que se hubieran podido ver afectados para que tomen las medidas adecuadas.</p>



			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Derechos:</h1>
				</div>
				<p className='text-secondary-color-2'>
					El usuario podrá ejercer los derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento, portabilidad de datos y a no ser objeto de decisiones individualizadas automatizadas, en relación con los datos objeto del tratamiento, ante el responsable del tratamiento en la dirección anteriormente mencionada, o por correo electrónico a rooms@helloflatmate.com, adjuntando copia de su DNI o documento equivalente. En caso de que no haya obtenido satisfacción en el ejercicio de sus derechos, podrá presentar una reclamación ante la Autoridad de Control en materia Protección de Datos competente, siendo ésta la Agencia Española de Protección de Datos, y cuyos datos de contacto están accesibles en 
					&nbsp;<a href='https://sedeagpd.gob.es/sede-electronica-web/vistas/formNuevaReclamacion/reclamacion.jsf' className='text-decoration-underline'>&nbsp;https://sedeagpd.gob.es/sede-electronica-web/vistas/formNuevaReclamacion/reclamacion.jsf</a>.
				</p>
			</div>

		</div>
	</div>
</div>)

export default TermsV1;