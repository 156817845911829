import React, { useState, createContext, useContext, useEffect } from "react"; import AuthService from "../services/AuthService";
import RoomService from "../services/RoomService";
import Modal from "../components/global-components/Modal";
import ProfileForm from "../components/shop-components/partials/my-account/profileForm";
import { params } from "../utils/functions";

let publicUrl = process.env.PUBLIC_URL + '/';
export const AuthContext = createContext(null);
const token = localStorage.getItem('accessToken');

const setAccessToken = token => localStorage.setItem('accessToken', token);

const removeAccessToken = () => localStorage.removeItem('accessToken');

const getMyForiteRooms = async () => (await RoomService.getMyFavoriteRooms())?.data;

const getMyPendingRooms = async () => (await RoomService.getMyPendingRooms())?.data;

const deletePendingRoom = async id => (await RoomService.deletePendingRoom(id))?.data;

const deleteFavoriteRoom = async id => (await RoomService.deleteFavoriteRooms(id))?.data;

const getMyBills = async () => (await RoomService.getMyBills()).data;

const payPendingBill = async (key) => (await RoomService.payPendingBill(key)).data;

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authLoading, setAuthLoading] = useState(false);
    let globalResponse = null;
    const handleLoadingEnd = () => {
        setAuthLoading(false)
        return globalResponse?.data?.message;
    }

    const changePassword = async (data) => {
        setAuthLoading(true);
        return AuthService.changePassword(data);
    }



    const login = async (data) => {
        setAuthLoading(true);
        const response = await AuthService.login(data);
        if (response && response?.data?.success) {
            const { user } = response?.data;
            setUser(user);
            setAccessToken(user?.api_token);
        }
    }

    const register = async data => {
        setAuthLoading(true);
        const response = await AuthService.register(data);
        if (response && response?.data?.success) {
            const { user } = response?.data;
            setUser(user);
            setAccessToken(user?.api_token);
        }
    }

    const updateProfile = async (data) => {
        setAuthLoading(true);
        const response = await AuthService.updateProfile(data);
        if (response && response?.data?.success) {
            const { user } = response?.data;
            setUser(user);
        }
        globalResponse = { ...response };
    }


    const handleLogout = () => {
        setUser(null)
        AuthService.logout().catch(err => console.log(err)).finally(() => removeAccessToken());

    }

    useEffect(() => {
        if (token) {
            AuthService
                .profile()
                .then(resp => resp.data)
                .then(({ data }) => setUser(data)).catch(err => {
                })
        }

    }, []);


    const state = {
        user,
        setUser,
        handleLogout,
        login,
        authLoading,
        register,
        changePassword,
        updateProfile,
        handleLoadingEnd,
        getMyForiteRooms,
        deleteFavoriteRoom,
        getMyPendingRooms,
        deletePendingRoom,
        getMyBills,
        payPendingBill,
        setAccessToken
    };
    return (<AuthContext.Provider value={state}>
        {children}
    </AuthContext.Provider>);
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        return {};
    }
    return context;
};