import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

let publicUrl = process.env.PUBLIC_URL + '/'

const AboutV1 = ({ roomsRef, options }) => {

	const { t } = useTranslation()
	const handleViewMore = () => {
		roomsRef && roomsRef.current.scrollIntoView();
	}
	const [viewMore, setViewMore] = useState(false)
	const history = useHistory()
	const handleNavigate = type => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
		history.replace(`/${type}`)
	}

	const handleShowMore = () => setViewMore(!viewMore);


	return <div className="ltn__about-us-area pt-120 pb-90 ">
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
						<img src={publicUrl + "assets/img/service/" + options?.img} alt="About Us Image" />
					</div>
				</div>
				<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">{options?.price}</h6>
							<h1 className="section-title text-capitalize">{t(options?.title)}</h1>
							<div className={`text-secondary-color-2`}>
								{new Array(options?.p).fill(() => 1).map((_, index) => <p key={options?.key + 'p' + index} className='text-secondary-color-2' hidden={!viewMore && index > 1}>{t(`services.${options?.key}.p` + (index + 1))}</p>)}
								{viewMore ? (<ul>
									{options?.li && <li className='text-secondary-color-2'>{t(`services.${options?.key}.list.title`)}</li>}
									{new Array(options?.li).fill(() => 1).map((_, index) => <li key={options?.key + 'li' + index} className='text-secondary-color-2'>{t(`services.${options?.key}.list.li` + (index + 1))}</li>)}
								</ul>) : null}
							</div>
							<span className='text-decoration-underline text-primary cursor-pointer' onClick={handleShowMore}>{!viewMore ? <>{t('services.readMore')}</> : <>{t('services.hide')}</>}</span>
						</div>
						<ul className="ltn__list-item-half clearfix">
							<li className='cursor-pointer' onClick={() => handleNavigate('helloColiving')}>
								<i className="flaticon-home-2" />
								helloColiving
							</li>
							<li className='cursor-pointer' onClick={() => handleNavigate('helloRoom')}>
								<i className="flaticon-mountain" />
								helloRoom
							</li>
							<li className='cursor-pointer' onClick={() => handleNavigate('helloLandlord')}>
								<i className="flaticon-heart" />
								helloLandlord
							</li>
							<li className='cursor-pointer' onClick={() => handleNavigate('helloStudio')}>
								<i className="flaticon-secure" />
								helloStudio
							</li>
						</ul>
						{/* 						<div className="ltn__callout bg-overlay-theme-05  mt-30">
							<p>"Enimad minim veniam quis nostrud exercitation <br />
								llamco laboris. Lorem ipsum dolor sit amet" </p>
						</div> */}
						<div className="btn-wrapper animated go-top">
							<button onClick={handleViewMore} className="theme-btn-1 btn btn-effect-1">{t('generals.viewRoom')}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}
export default AboutV1