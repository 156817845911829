import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
let publicUrl = process.env.PUBLIC_URL + '/'
let imagealt = 'image'

const Banner = ({ options }) => {


	return <div className="ltn__slider-area ltn__slider-3 section-bg-1 go-top">
		<div className="ltn__slide-one-active">
			<div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal ltn__slide-item-3 position-relative">
				<img className='position-absolute top-0 w-100 h-100 object-cover'alt='Not found' src={options?.background} />
				<div className='position-absolute top-0 w-100 h-100 bg-dark opacity-50' style={{ zIndex: 1 }}>

				</div>
				<div className="ltn__slide-item-inner  text-right text-center">
					<div className="container">
						<div className="row ">
							<div className="col-lg-12 align-self-center">
								<div className="slide-item-info">
									<div className="slide-item-info-inner ltn__slide-animation py-5">
										<h1 className="slide-title animated text-white py-5 fw-bold">{options?.title}</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*  */}
		</div>
	</div >
}
export default Banner