import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import AboutV5 from './section-components/about-v5';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import { varietyOptions } from '../utils/navigation';
import { useTranslation } from 'react-i18next';

const Service_V1 = () => {
    const { t } = useTranslation();
    return <div>
        <TopBar />
        <Navbar />
        <PageHeader headertitle={t('services.headerTitle')} subheader={t('services.subheader')}  />
        {
            varietyOptions.map(el => <AboutV5 key={`service-${el?.key}`} el={el} />)
        }
        <Footer />
    </div>
}

export default Service_V1

