import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useAuth } from '../../contexts/AuthContextProvider';
import { toastAction } from '../../utils/functions';

const Register = () => {
	const { register, authLoading, handleLoadingEnd } = useAuth();
	const [terms, setTerms] = useState(false);
	const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const handleChange = (e, setData) => setData(e.target.checked);

	const handleRegister = e => {
		e.preventDefault();
		if (terms && privacyPolicy) {
			const body = Object.fromEntries(new FormData(e.target).entries());
			toastAction(register(body), 'Cargando...', handleLoadingEnd).catch(err => console.log(err))
		}
	}
	return (<div className="ltn__login-area pb-110">
		<div className="container">
			<div className="row">
				<div className="col-lg-6 offset-lg-3">
					<div className="account-login-inner">
						<form action="#" className="ltn__form-box contact-form-box" onSubmit={handleRegister}>
							<input type="text" name="name" placeholder="Nombre" />
							<input type="text" name="lastname" placeholder="Apellidos" />
							<input type="text" name="dni" placeholder="DNI*" />
							<input type="email" name="email" placeholder="Email*" />
							<input type="password" name="password" placeholder="Contrase&ntilde;a*" />
							<input type="password" name="password_confirmation" placeholder="Confirmar Contrase&ntilde;a*" />
							<label className="checkbox-inline">
								<input type="checkbox" defaultValue onChange={e => handleChange(e, setTerms)} />&nbsp;
								I consent to Herboil processing my personal data in order to send personalized marketing material in accordance with the consent form and the privacy policy.
							</label>
							<label className="checkbox-inline">
								<input type="checkbox" defaultValue onChange={e => handleChange(e, setPrivacyPolicy)} /> &nbsp;
								By clicking "create account", I consent to the privacy policy.
							</label>
							<div className="btn-wrapper">
								<button className="theme-btn-1 btn reverse-color btn-block" disabled={authLoading} type="submit">{!authLoading ? <>Registrarme</> : <>Cargando...</>}</button>
							</div>
						</form>
						<div className="by-agree text-center">
							<p>By creating an account, you agree to our:</p>
							<p><Link to="/terms">TERMS OF CONDITIONS  &nbsp; &nbsp; | &nbsp; &nbsp;  PRIVACY POLICY</Link></p>
							<div className="go-to-btn mt-50 go-top">
								<Link to="/login">ALREADY HAVE AN ACCOUNT ?</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>)
}

export default Register