import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ok from "../assets/payments/ok.svg"

const Ok = () => {

    const { t } = useTranslation();

    return <div>
        <TopBar />
        <Navbar />
        <PageHeader headertitle={t('payments.index')} />

        <div className="ltn__about-us-area pt-120 pb-90 ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                            <img src={ok} alt="About Us Image" width={400} height={400} />
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">{t('payments.ok')}</h6>
                            </div>
                            {/* 						<div className="ltn__callout bg-overlay-theme-05  mt-30">
							<p>"Enimad minim veniam quis nostrud exercitation <br />
								llamco laboris. Lorem ipsum dolor sit amet" </p>
						</div> */}
                            <div className="btn-wrapper animated go-top">
                                <Link className="theme-btn-1 btn btn-effect-1 m-auto" to={'/'}>{t('generals.goToHome')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </div>
}

export default Ok;
