import React from 'react';
import { baseUrlServer, email, phoneNumber } from '../../utils/environment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const LegalWarningV1 = () => (<div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
	<div className="container">
		<div className="row">
			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>DATOS DE LA ENTIDAD</h1>
				</div>
				<p className='text-secondary-color-2'>
					En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico, HELLO FLAT MATE, S.L. le informa que se encuentra inscrita en el Registro Mercantil de VALENCIA T 9339, L 6621, F 109, S 8, H V 144134, I/A 4(18.01.23), siendo sus datos identificativos los siguientes:</p>

				<ul className='text-secondary-color-2'>
					<li>Denominación social: HELLO FLAT MATE, S.L.</li>
					<li>C.I.F.: B98358963</li>
					<li>Domicilio social: C/ CAMPOAMOR 8 1 46021 - VALENCIA (VALENCIA)</li>
					<li>Teléfono: <a href={`tel:${phoneNumber.split(' ').join('')}`} className='text-decoration-underline'>{phoneNumber}</a></li>
					<li>Correo electrónico a efectos de comunicación: <a href={`mailto:${email}`} className='text-decoration-underline'>{email}</a></li>
					<li>Dominio WEB: <a href={baseUrlServer} className='text-decoration-underline'>{baseUrlServer}</a></li>
				</ul>
				<p className='text-secondary-color-2'>
					Todas las notificaciones y comunicaciones entre los usuarios y HELLO FLAT MATE, S.L. se considerarán eficaces, a todos los efectos, cuando se realicen a través de correo postal o cualquier otro medio de los detallados anteriormente.
				</p>
			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>CONDICIONES DE ACCESO Y UTILIZACIÓN</h1>
				</div>
				<p className='text-secondary-color-2'>
					El presente aviso legal regula el uso del sitio  <a href={baseUrlServer} className='text-decoration-underline'>{baseUrlServer}</a> (en adelante, LA WEB), del que es titular HELLO FLAT MATE, S.L..
				</p>
				<p className='text-secondary-color-2'>
					La navegación por la web de HELLO FLAT MATE, S.L. atribuye la condición de usuario del mismo e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal, que pueden sufrir modificaciones.
				</p>
				<p className='text-secondary-color-2'>
					El sitio web y sus servicios son de acceso libre y gratuito, no obstante, HELLO FLAT MATE, S.L. condiciona la utilización de algunos de los servicios ofrecidos en su web a la previa cumplimentación del correspondiente formulario.</p>

				<p className='text-secondary-color-2'>
					Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos, tecnología, software, así como su diseño y códigos fuente, constituyen una obra cuya propiedad pertenece a HELLO FLAT MATE, S.L., sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web.	</p>

				<p className='text-secondary-color-2'>
					El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a HELLO FLAT MATE, S.L. y será el único responsable de las manifestaciones falsas o inexactas que realice.
				</p>

				<p className='text-secondary-color-2'>
					El usuario se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal. El usuario responderá frente a HELLO FLAT MATE, S.L. o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.

				</p>

			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>POLÍTICA DE ENLACES Y EXENCIONES DE RESPONSABILIDADES</h1>
				</div>
				<p className='text-secondary-color-2'>
					HELLO FLAT MATE, S.L. no se hace responsable del contenido de las páginas web a las que el usuario pueda acceder a través de los enlaces establecidos en LA WEB y declara que en ningún caso procederá a examinar o ejercitar ningún tipo de control sobre el contenido de otras páginas de la red. Asimismo, tampoco garantizará la disponibilidad técnica, exactitud, veracidad, validez o legalidad de páginas ajenas a su propiedad a las que se pueda acceder por medio de los enlaces.	</p>


				<p className='text-secondary-color-2'>
					HELLO FLAT MATE, S.L. declara haber adoptado todas las medidas necesarias para evitar cualesquiera daños que, a los usuarios de LA WEB, pudieran derivarse de la navegación por la misma. En consecuencia, HELLO FLAT MATE, S.L. no se hace responsable, en ningún caso, de los eventuales daños que por la navegación por Internet pudiera sufrir el usuario.
				</p>

			</div>



			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>USO DE COOKIES Y DATOS ESTADÍSTICOS</h1>
				</div>
				<p className='text-secondary-color-2'>
					Esta página web puede recoger datos de sus visitantes por medio del uso de cookies, donde se recabará información personal relacionada con su navegación. Para conocer de manera clara y precisa las cookies que utilizamos, cuáles son sus finalidades y cómo puede configurarlas o deshabilitarlas, consulte nuestra <Link className='text-secondary-color-2 text-decoration-underline' to='/cookies'>política de cookies</Link>, en su caso. </p>

				<p className='text-secondary-color-2'>

					El usuario tiene la posibilidad de configurar su navegador de modo que se le informe de la recepción de cookies, pudiendo, si así lo desea, impedir que sean instaladas en su disco duro.
				</p>

			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>PROTECCIÓN DE DATOS </h1>
				</div>
				<p className='text-secondary-color-2'>
					En el caso de que en la web se recojan datos de carácter personal, para más información consultar la <Link className='text-secondary-color-2 text-decoration-underline' to='/terms'>política de privacidad</Link>.
				</p>


			</div>



			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>RESOLUCIÓN DE CONTROVERSIAS. LEY APLICABLE Y JURISDICCIÓN</h1>
				</div>
				<p className='text-secondary-color-2'>
					Las presentes Condiciones Generales de Uso, así como el uso del Espacio Web, se regirán por la legislación española. Para la resolución de cualquier controversia las partes se someterán a los Juzgados y Tribunales del domicilio social del responsable del sitio web.
				</p>
				<p className='text-secondary-color-2'>
					En el supuesto de que cualquier estipulación de las presentes Condiciones Generales de Uso resultara inexigible o nula en virtud de la legislación aplicable o como consecuencia de una resolución judicial o administrativa, dicha inexigibilidad o nulidad no hará que las presentes Condiciones Generales de Uso resulten inexigibles o nulas en su conjunto. En dichos casos, la empresa procederá a la modificación o sustitución de dicha estipulación por otra que sea válida y exigible y que, en la medida de lo posible, consiga el objetivo y pretensión reflejados en la estipulación original.	</p>
			</div>

		</div>
	</div>
</div>)

export default LegalWarningV1;