import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from "react-router-dom";
import HomeV8 from './components/home-v8';
import HomeV1 from './components/home-v1';
import About from './components/about';
import Service from './components/service';
import ServiceDetails from './components/service-details';
import Portfolio from './components/portfolio';
import PortfolioV2 from './components/portfolio-v2';
import PortfolioDetails from './components/portfolio-details';
import Team from './components/team';
import TeamDetails from './components/team-details';
import Faq from './components/faq';
import ComingSoon from './components/coming-soon';
import Error from './components/404';
import Location from './components/location';

import Shop from './components/shop';
import ShopGrid from './components/shop-grid';
import ProdductDetails from './components/product-details';
import ShopLeftSidebar from './components/shop-left-sidebar';
import ShopRightSidebar from './components/shop-right-sidebar';

import BlogGrid from './components/blog-grid';
import BlogLeftSidebar from './components/blog-left-sidebar';
import BlogRightSidebar from './components/blog-right-sidebar';
import Blog from './components/blog';

import BlogDetails from './components/blog-details';
import Contact from './components/contact';
import Cart from './components/cart';
import Checkout from './components/checkout';
import MyAccount from './components/my-account';
import Login from './components/login';
import Register from './components/register';
import AddListing from './components/add-listing';
import Wishlist from './components/wishlist';
import OrderTracking from './components/order-tracking';
import History from './components/history';
import "./index.css"
import { LayoutContextProvider } from './contexts/LayoutContext.js';
import Term_v1 from './components/terms.js';
import LegalWarning_v1 from './components/legalWarning.js';
import { AuthContextProvider } from './contexts/AuthContextProvider.js';
import './locales/index.js'
import Ok from './components/ok.js';
import Ko from './components/ko.js';
import { BrowserRouter, Redirect } from 'react-router-dom/cjs/react-router-dom.min.js';
import { getLanguage } from './utils/functions.js';
import Cookies from './components/cookies.js';

const Root = () => {
    const [language, setLanguage] = useState(getLanguage());


    return (<BrowserRouter basename={`/${language}`}>
        <LayoutContextProvider setLanguage={setLanguage}>
            <div>
                <Switch>
                    <Route exact path="/" component={HomeV8} />
                    <Route path="/about" component={About} />
                    <Route path="/legal-warning" component={LegalWarning_v1} />
                    <Route path="/terms" component={Term_v1} />
                    <Route path="/cookies" component={Cookies} />
                    <Route path="/service" component={Service} />
                    <Route path="/roomview/:codeHome/:codeRoom" component={ProdductDetails} />
                    <Route path="/service-details" component={ServiceDetails} />
                    <Route path="/portfolio" component={Portfolio} />
                    <Route path="/portfolio-v2" component={PortfolioV2} />
                    <Route path="/portfolio-details" component={PortfolioDetails} />
                    <Route path="/team" component={Team} />
                    <Route path="/team-details" component={TeamDetails} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/ok" component={Ok} />
                    <Route path="/ko" component={Ko} />
                    <Route path="/coming-soon" component={ComingSoon} />
                    <Route path="/404" component={Error} />
                    <Route path="/location" component={Location} />
                    <Route path="/shop" component={Shop} />
                    <Route path="/roomsearch" component={ShopGrid} />
                    <Route path="/flatsearch" component={ShopGrid} />
                    <Route path="/shop-left-sidebar" component={ShopLeftSidebar} />
                    <Route path="/shop-right-sidebar" component={ShopRightSidebar} />

                    <Route path="/blog-grid" component={BlogGrid} />
                    <Route path="/blog-left-sidebar" component={BlogLeftSidebar} />
                    <Route path="/blog-right-sidebar" component={BlogRightSidebar} />
                    <Route path="/blog" component={Blog} />

                    <Route path="/blog-details" component={BlogDetails} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/cart" component={Cart} />
                    <Route path="/checkout" component={Checkout} />
                    <Route path="/add-listing" component={AddListing} />
                    <Route path="/wishlist" component={Wishlist} />
                    <Route path="/order-tracking" component={OrderTracking} />
                    <Route path="/history" component={History} />
                    <Route path="/my-account" component={MyAccount} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/:type" component={HomeV1} />
                    <Route path="**" component={<Redirect to={'/'} />} />
                </Switch>
            </div>
        </LayoutContextProvider>
    </BrowserRouter>)
}

export default Root;

ReactDOM.render(
    <AuthContextProvider>
        <Root />
    </AuthContextProvider>, document.getElementById('quarter'));
