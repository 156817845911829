import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { useAuth } from "../../../../contexts/AuthContextProvider"
import { getFormatedDate, numberAsEuro, toastAction } from "../../../../utils/functions";
import { toastErrorMessage } from "../../../../utils/functions/toast";
import { useTranslation } from "react-i18next";
import Room from "./Room";



export default function Favorites({ rooms, setRooms }) {
    const { deleteFavoriteRoom } = useAuth();


    const handleDeleteFavoriteRoom = (el) => {
        toastAction(deleteFavoriteRoom(el?.id)).then(response => {
            setRooms(rooms => ({ ...rooms, data: rooms?.data?.filter(element => element?.id !== el?.id) }));
        }).catch(err => console.log(err));
    }

    return (
        <div className="tab-pane fade" id="ltn_tab_1_6">
            <div className="ltn__myaccount-tab-content-inner">
                <div className="ltn__my-properties-table table-responsive">
                    <table className="table">
                        {rooms && (<thead>
                            <tr>
                                <th scope="col">Propiedades</th>
                                <th scope="col" />
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Opciones</th>
                            </tr>
                        </thead>)}
                        <tbody>
                            {
                                rooms ? (<>
                                    {
                                        rooms?.data?.length ? rooms?.data?.map((el, index) => <Room setRooms={setRooms} el={el} handleAction={() => handleDeleteFavoriteRoom(el)} key={`room-${el?.id}-${index}`} />)
                                            : <tr><td colSpan={5}><h1 className="text-secondary-color-2 fw-bold">No tiene habitaciones marcadas como favoritas</h1></td></tr>
                                    }
                                </>) : null
                            }
                        </tbody>
                    </table>
                </div>
                {
                    rooms && rooms?.data?.length ? (<div className="ltn__pagination-area text-center">
                        <div className="ltn__pagination">
                            <ul>
                                <li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
                                {
                                    new Array(rooms?.last_page).map((el, index) => <li key={`page-${index}`} className={rooms?.current_page === index ? 'active' : ''}><Link to="#">{index}</Link></li>)
                                }
                                <li><Link to="#" disabled={rooms?.current_page === rooms?.last_page} ><i className="fas fa-angle-double-right" /></Link></li>
                            </ul>
                        </div>
                    </div>) : null
                }

            </div>
        </div>
    )
}

