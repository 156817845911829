import React from "react"
import { useAuth } from "../../../../contexts/AuthContextProvider"
import { useTranslation } from "react-i18next";

export default function Dashboard() {
    const { user, handleLogout } = useAuth();
    const { t } = useTranslation()
    return (<div className="tab-pane fade active show" id="ltn_tab_1_1">
        <div className="ltn__myaccount-tab-content-inner">
            <p className="d-flex align-items-center">{t('account.dashboard.welcome')}&nbsp;<strong>{user?.name}</strong>  <small className="ms-auto"><a className="cursor-pointer d-flex align-items-center" onClick={handleLogout}>{t('home.links.logout')}&nbsp;<i className="fas fa-sign-out-alt" /></a></small></p>
            <p>{t('account.dashboard.text')}</p>
        </div>
    </div>)
}