import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { team } from '../../mocks/about';

class TeamV1 extends Component {

	render() {

		return <div className="ltn__team-area pt-115 pb-90 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Nuestro Equipo</h6>
							<h1 className="section-title text-secondary-color-2">Qui&eacute;nes S&oacute;mos?</h1>
						</div>
					</div>
				</div>
				<div className="row justify-content-center">

					{
						team.map(el => <TeamCard key={el?.id} el={el} />)
					}

				</div>
			</div>
		</div>
	}
}

const TeamCard = ({ el }) => (<div className="col-lg-4 col-sm-6">
	<div className="ltn__team-item ltn__team-item-3---">
		<div className="team-img">
			<img src={el?.img} alt={'image'} />
		</div>
		<div className="team-info">
			<h4><Link to="/team-details" className='text-secondary-color-2 fw-bold'>{el?.name}</Link></h4>
			<h6 className="ltn__secondary-color">Cont&aacute;cto</h6>
			<div className="ltn__social-media">
				<ul className='text-secondary-color-2'>
					<li><a href={`mailto:${el?.email}`}><i className="fa fa-envelope" /> {el?.email}</a></li>
					<li><a href={`tel:${el?.phone.split(' ').join('')}`}><i className="fa fa-phone" /> {el?.phone}</a></li>
				</ul>
			</div>
		</div>
	</div>
</div>)

export default TeamV1