import React, { Component } from 'react';
import { Link } from 'react-router-dom';


const CookiesV1 = () => (<div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
	<div className="container">
		<div className="row">
			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>
						PROTOCOLO PARA LA OBTENCIÓN DEL CONSENTIMIENTO EXPRESO
					</h1>
				</div>
				<p className='text-secondary-color-2'>
					En HELLO FLAT MATE, S.L. utilizamos las cookies u otros archivos de funcionalidad similar (en adelante, “cookies”) que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación, o personalizar la forma en que se muestra el contenido.</p>

				<p className='text-secondary-color-2'>
					El objetivo de esta política es informarle de manera clara y detallada de qué es una cookie, cuál es su finalidad, qué tipo de cookies utilizamos y cómo configurarlas o en su caso deshabilitarlas.
				</p>
			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>¿Qué son las cookies?</h1>
				</div>
				<p className='text-secondary-color-2'>
					Una cookie es un pequeño archivo de texto que se almacena en su navegador cuando visita nuestra página web y que guarda información sobre la navegación que realiza para hacer más eficiente la experiencia de usuario. Algunas cookies resultan esenciales para el buen funcionamiento de las páginas web, como es el caso de las cookies técnicas o de personalización de la interfaz de usuario, aunque otras, como las cookies de análisis o las de publicidad comportamental, requieren que le informemos y recabar el consentimiento sobre su uso.
				</p>
				<p className='text-secondary-color-2'>
					A continuación, y con el objetivo de que pueda prestar un consentimiento plenamente informado, le detallamos la información de en qué consiste y cuál es la finalidad de cada tipo de cookie:
				</p>
				<p className='text-secondary-color-2'>
					Las <strong className='fw-bold'>cookies técnicas</strong> son aquellas que, permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan, incluyendo la gestión y operativa de la página web y habilitar sus funciones y servicios, como, por ejemplo, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, gestionar el pago, … La página web no puede funcionar adecuadamente sin estas cookies por lo que se consideran necesarias.
				</p>
				<p className='text-secondary-color-2'>
					Las <strong className='fw-bold'>cookies de preferencias</strong> o <strong className='fw-bold'>personalización:</strong> permiten a la página web recordar información que cambia la forma en que la página se comporta o el aspecto que tiene de modo que el usuario acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de otros usuarios, como, por ejemplo, el idioma, el número de resultados a mostrar cuando el usuario realiza una búsqueda o la región en la que el usuario se encuentra. Si es el propio usuario quien elige esas características, por ejemplo, marcando la bandera del idioma, se considera un servicio expresamente solicitado siempre y cuando las cookies obedezcan exclusivamente a la finalidad seleccionada de personalización.
				</p>
				<p className='text-secondary-color-2'>
					Las <strong className='fw-bold'>cookies de análisis</strong> o <strong className='fw-bold'>medición:</strong> son aquellas que, tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</p>

				<p className='text-secondary-color-2'>
					Las <strong className='fw-bold'>cookies de marketing </strong>o <strong className='fw-bold'>publicidad comportamental:</strong> almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar anuncios relevantes y atractivos para el usuario individual y, por lo tanto, más valiosos para los terceros anunciantes.
				</p>


			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>¿Para qué utiliza las cookies en esta página web y cuáles son?</h1>
				</div>
				<p className='text-secondary-color-2'>
					El detalle de las cookies utilizadas en esta página web es el siguiente:
				</p>
				<table class="table">
					<thead>
						<tr>
							<th scope="col">Cookie</th>
							<th scope="col">Tipo</th>
							<th scope="col">Propósito</th>
							<th scope="col">Más información</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>Sessi&oacute;n</th>
							<td>Propias</td>
							<td>Obtiene datos personales de los usuarios como nombres o apellidos</td>
							<td>@mdo</td>
						</tr>
					</tbody>
				</table>

				<p className='text-secondary-color-2'>
					Para las cookies de análisis y de publicidad necesitamos su consentimiento que podremos obtener si pulsa en el botón “Acepto” en el mensaje de información que se muestra al acceder a nuestra página web.
				</p>
				<p className='text-secondary-color-2'>
					Si desactiva las cookies, podrá seguir accediendo a la web pero puede que la navegación por esta no sea óptima y alguno de los servicios ofrecidos no funcionen correctamente.	</p>
				<p className='text-secondary-color-2'>
					Si en un futuro HELLO FLAT MATE, S.L. llegara a utilizar tipos de cookies diferentes a las contempladas en esta Política de Cookies para prestar nuevos servicios o fuera necesario adaptarla a nuevas exigencias legislativas, se lo notificaremos.	</p>
			</div>



			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>¿Cómo puedo desactivar o eliminar las cookies?</h1>
				</div>
				<p className='text-secondary-color-2'>
					Tenga en cuenta que, si acepta las cookies de terceros, deberá eliminarlas desde las opciones del navegador o desde el sistema ofrecido por el propio tercero. Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>

				<ul className='text-secondary-color-2'>
					<li><span className='fw-bold text-underline'>Microsoft Internet Explorer</span>: menú Herramientas > Opciones de Internet > Privacidad > Configuración. </li>
					<li><span className='fw-bold text-underline'>Firefox</span>: menú Herramientas > Opciones > Privacidad > Cookies. </li>
					<li><span className='fw-bold text-underline'>Chrome</span>: menú Opciones > Opciones avanzadas > Privacidad. </li>
					<li><span className='fw-bold text-underline'>Safari</span>: menú Preferencias/Privacidad. </li>
					<li><span className='fw-bold text-underline'>Safari para IOS (iPhone y iPad)</span>: Opción Ajustes > Safari </li>
					<li><span className='fw-bold text-underline'>Chrome para Android</span>: Configuración > Configuración de sitios web > Cookies </li>
					<li><strong className='fw-bold'>Otros navegadores:</strong> consulte la documentación del navegador que tenga instalado.  </li>
				</ul>
			</div>


			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Transferencias de datos a terceros países:</h1>
				</div>
				<p className='text-secondary-color-2'>
					Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (ver los enlaces facilitados en el apartado “Cookies de terceros”).	</p>
			</div>
			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Elaboración de perfiles:</h1>
				</div>
				<p className='text-secondary-color-2'>
					No se elaborará ningún tipo de “perfil” en base a la información facilitada ni se tomarán decisiones automatizadas en base a perfiles.
				</p>
			</div>
			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Periodo de conservación de los datos:</h1>
				</div>
				<p className='text-secondary-color-2'>
					Los datos obtenidos a través de las cookies se conservarán por un periodo máximo de 24 meses, tras dicho periodo volveremos a recabar su consentimiento.
				</p>
			</div>
			<div className="col-12 align-self-center">
				<div className="about-us-img-wrap about-img-left">
					<h1 className='text-secondary-color-2 fw-bold'>Derechos de los interesados:</h1>
				</div>
				<p className='text-secondary-color-2'>
					<strong className='fw-bold'><i>AVISO:</i></strong> El responsable deberá cumplimentar la tabla arriba incluida indicando, para todas y cada una de las cookies que utiliza en su web, los datos relativos a cada uno de los campos consignados.	</p>

				<p className='text-secondary-color-2'>
					<strong className='fw-bold'><i>AVISO:</i></strong> El grado de granularidad en el panel a la hora de mostrar la selección de cookies deberá valorarlo el editor del sitio web. Si bien es aconsejable que se tengan en cuenta las siguientes reglas:
				</p>

				<ul className='text-secondary-color-2'>
					<li>Como mínimo, deberían agruparse las cookies por su finalidad</li>
					<li>En relación con las cookies de terceros es suficiente con identificarlos por su nombre o por la marca con la que se identifican de cara al público, sin incluir la denominación social completa.</li>
					<li>Debe evitarse el grado máximo de granularidad (selección cookie a cookie), ya que el exceso de información dificulta la toma de decisiones. </li>
				</ul>

				<p className='text-secondary-color-2'>
					<strong className='fw-bold'><i>AVISO:</i></strong> Se recomienda como mejor práctica la renovación del consentimiento a intervalos apropiados. La Agencia considera buena práctica que la validez del consentimiento prestado por un usuario para el uso de una determinada cookie no tenga una duración superior a 24 meses y que durante este tiempo se conserve la selección realizada por el usuario sobre sus preferencias.
				</p>

			</div>
		</div>
	</div>
</div>)

export default CookiesV1;