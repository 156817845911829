import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { socialNetworks } from '../../utils/navigation';

class Social extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__social-media">
			<ul>
				{
					socialNetworks.map((el, index) => <li key={`single-sociak-items-${index}`}><a href={`${el?.link}`} title={el?.title} className='social'><i className={el?.icon} /></a></li>)
				}
			</ul>
		</div>
	}
}

export default Social