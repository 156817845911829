import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import MyAccount from './shop-components/my-account';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import IsAuth from './global-components/isAuth';
import { useTranslation } from 'react-i18next';

const MyaccountV1 = () => {
    const { t } = useTranslation()
   
   return <IsAuth>
        <div>
            <TopBar />
            <Navbar />
            <PageHeader headertitle={t('account.title')} />
            <MyAccount />
            <Footer />
        </div>
    </IsAuth>
}

export default MyaccountV1

