import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import Team from './section-components/team-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import PdfViewer from './global-components/PdfViewer';
import FloatingActionButton from './global-components/FloatingActionButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
let publicUrl = process.env.PUBLIC_URL + '/';
const About_v1 = () => {

    const history = useHistory();
    const handleClick = () => history.push('/contact');

    return <div>
        <TopBar />
        <Navbar />
        <PageHeader headertitle="Conoc&eacute;nos" customclass={' mb-0'} />
        {/*      <AboutV4 /> */}
        <PdfViewer source={'/build/CONOCENOS.html'} />
        <FloatingActionButton label={
            <i className='fa fa-phone' />
        }
            handleClick={handleClick}
        />
        {/*        <Team /> */}
        <Footer />
    </div>
}

export default About_v1

