import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import { zones as mockZones, dates as mockDates, universities as mockUniversities } from '../mocks/home.js'
import HomeService from "../services/HomeService.js";
export const HomeContext = createContext(null);




export const HomeContextProvider = ({ children }) => {

    useEffect(() => {

    }, [])


    return (<HomeContext.Provider value={{}}>{children}</HomeContext.Provider>)
}

export const useHome = () => {
    const context = useContext(HomeContext);
    if (context === undefined) {
        return {};
    }
    return context;
}