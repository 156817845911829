import { email, phoneNumber } from "../utils/environment";
import { strRandom } from "../utils/functions";

export const team = [
    {
        id: strRandom(20) + strRandom(20),
        name: 'Alberto',
        img: 'https://www.helloflatmate.com/img/team/alberto.png',
        email: email,
        phone: phoneNumber,
    },
    {
        id: strRandom(20) + strRandom(20),
        name: 'Stephanie',
        img: 'https://www.helloflatmate.com/img/team/stephanie.png',
        email: email,
        phone: phoneNumber,
    },
    {
        id: strRandom(20) + strRandom(20),
        name: 'Mónica',
        img: 'https://www.helloflatmate.com/img/team/monica.png',
        email: email,
        phone: phoneNumber,
    },
];