import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export function Slideshow({ images, element }) {
    const [index, setIndex] = useState(0);
    const history = useHistory();
    const handleNavigate = (url) => history.push(url)

    return (
        <div className="slideshow psotion-relative">
            <button onClick={e => {
                e.preventDefault();
                setIndex(prev => prev === 0 ? 0 : prev - 1)
            }} className="ms-3 position-absolute top-50 start-0 fs-1  translate-middle-y bg-transparent text-white" style={{ zIndex: 9999999, height: 'fit-content', textShadow: '1px 1px 2px black;' }}><i className="fas fa-angle-left" alt="Arrow Icon" /></button>
            <button onClick={e => {
                e.preventDefault();
                setIndex(prev => prev === images.length - 1 ? 0 : prev + 1)
            }} className="me-3 position-absolute top-50 end-0 fs-1  translate-middle-y bg-transparent text-white" style={{ zIndex: 9999999, height: 'fit-content', textShadow: '1px 1px 2px black;' }}><i className="fas fa-angle-right" alt="Arrow Icon" /></button>
            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}            >
                {images.map((el, index) => {
                    return (
                        <div
                            onClick={() => handleNavigate(`/roomview/${element?.code_home}/${element?.code}`)}
                            className="slide position-relative"
                            key={index}
                            style={{ backgroundImage: `url(${el?.img})`, maxHeight: '250px' }}
                        >

                        </div>
                    )
                })}

            </div>
        </div>
    );
}
