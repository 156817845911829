import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useLayout } from '../contexts/LayoutContext';
import RoomService from '../services/RoomService';
import { toastErrorMessage } from '../utils/functions/toast';
import Modal from './global-components/Modal';


const Product_Details = () => {
    const { codeHome, codeRoom } = useParams();
    const [room, setRoom] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [suggestions, setSuggestion] = useState(null);
    const history = useHistory();

    useEffect(() => {

        const $ = window?.$;
        if (codeHome && codeRoom) {
            RoomService.getRoomById(codeHome, codeRoom)
                .then(resp => {
                    $('.ltn__slide-one-active').slick('destroy');
                    resp.data.data.deleted_at && setShowModal(true)
                    setRoom(resp.data.data)
                    setSuggestion(resp.data.suggestion)
                })
                .catch(({ response }) => toastErrorMessage(response?.data?.message))
        }
    }, [codeHome, codeRoom])


    const handleNavigateBack = () => history.goBack()

    return <>
        {<Modal noHeader centered title='Activar Cuenta' wrapperDisabled className='w-sm-100' showModal={showModal} setShowModal={setShowModal}>
            <p className='text-center mb-0'>
                <span className='fs-3'>
                    Esta habitaci&oacute;n no se encuentra disponible
                </span>
                <br />
                <button onClick={handleNavigateBack} className='text-primary text-center m-auto bg-transparent text-decoration-underline'>
                    Ir atras
                </button>
            </p>

        </Modal>}
        <div>
            <TopBar />
            <Navbar />
            <ProductDetails room={room} suggestions={suggestions} setRoom={setRoom} />
            <Footer />
        </div>
    </>


}

export default Product_Details

