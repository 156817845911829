import React, { Component, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContextProvider';
import { toastAction } from '../../utils/functions';
import { useTranslation } from 'react-i18next';
let publicUrl = process.env.PUBLIC_URL + '/'


const Login = () => {

	const { login, authLoading, handleLoadingEnd, register } = useAuth();
	const { t } = useTranslation()
	const registerFormRef = useRef();
	const handleLogin = async e => {
		e.preventDefault();
		const data = Object.fromEntries(new FormData(e.target).entries());
		toastAction(login(data), 'Cargando...', handleLoadingEnd).catch(err => console.log(err))
	}

	const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const handleChange = (e, setData) => setData(e.target.checked);

	const handleRegister = e => {
		e.preventDefault();
		if (privacyPolicy) {
			const body = Object.fromEntries(new FormData(e.target).entries());

			toastAction(register(body), 'Cargando...', () => {
				handleLoadingEnd();
				return 'Usuario registrado satisfactoriamente, se la ha enviado un email de activaciòn de cuenta';
			}).then(res => registerFormRef && registerFormRef.current.reset())
				.catch(err => {
					console.log(err)
				})
		}
	}



	return (<div>
		<div className="ltn__login-area pb-65">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<h4 className='text-secondary-color-2 fw-bold'> {t('account.has_account')}</h4>
						{/*<p className='text-secondary-color-2'>Accede a tus datos personales iniciando sessi&oacute;n<br /></p>*/}
						<div className="account-login-inner">
							<form onSubmit={handleLogin} className="ps-0 ltn__form-box contact-form-box">
								<input type="text" name="email" placeholder="Email*" />
								<input type="password" name="password" placeholder="Password*" />
								<div className="btn-wrapper mt-0">
									<button className="theme-btn-1 btn btn-block" disabled={authLoading} type="submit">{<>{t('account.init')}</>}</button>
								</div>
								<div className="go-to-btn mt-20">
									{/* <a href="#" title="Forgot Password?" data-bs-toggle="modal" data-bs-target="#ltn_forget_password_modal"><small>FORGOTTEN YOUR PASSWORD?</small></a>
					*/}</div>
							</form>
						</div>
					</div>
					<div className="col-lg-6">

						<div className="account-create text-center">
							<h4 className='text-secondary-color-2 fw-bold'>{t('account.no_account')}</h4>
							{/*<p className='text-secondary-color-2'>Registrarse</p>*/}
							<form action="#" className="ltn__form-box contact-form-box ps-0" onSubmit={handleRegister} ref={registerFormRef}>
								<input type="text" name="name" placeholder="Nombre" />
								<input type="text" name="lastname" placeholder="Apellidos" />
								<input type="text" name="dni" placeholder="DNI*" />
								<input type="email" name="email" placeholder="Email*" />
								<input type="password" name="password" placeholder="Contrase&ntilde;a*" />
								<input type="password" name="password_confirmation" placeholder="Confirmar Contrase&ntilde;a*" />
								{/*<label className="checkbox-inline">
									<input type="checkbox" defaultValue onChange={e => handleChange(e, setTerms)} />&nbsp;
									I consent to Herboil processing my personal data in order to send personalized marketing material in accordance with the consent form and the privacy policy.
								</label>*/}
								<label className="checkbox-inline">
									<input type="checkbox" defaultValue onChange={e => handleChange(e, setPrivacyPolicy)} /> &nbsp;
									By clicking "create account", I consent to the privacy policy.
								</label>
								<div className="btn-wrapper">
									<button className="theme-btn-1 btn reverse-color btn-block" disabled={authLoading} type="submit">{!authLoading ? <>{t('account.reg')}</> : <>Cargando...</>}</button>
								</div>
							</form>
						</div>

						{/* <div className="account-create text-center pt-50">
							<h4>DON'T HAVE AN ACCOUNT?</h4>
							<p>Add items to your wishlistget personalised recommendations <br />
								check out more quickly track your orders register</p>
							<div className="btn-wrapper go-top">
								<Link to="/register" className="theme-btn-1 btn black-btn">CREATE ACCOUNT</Link>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</div>
		<div className="ltn__modal-area ltn__add-to-cart-modal-area----">
			<div className="modal fade" id="ltn_forget_password_modal" tabIndex={-1}>
				<div className="modal-dialog modal-md" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="ltn__quick-view-modal-inner">
								<div className="modal-product-item">
									<div className="row">
										<div className="col-12">
											<div className="modal-product-info text-center">
												<h4>FORGET PASSWORD?</h4>
												<p className="added-cart"> Enter you register email.</p>
												<form action="#" className="ltn__form-box">
													<input type="text" name="email" placeholder="Type your register email*" />
													<div className="btn-wrapper mt-0">
														<button className="theme-btn-1 btn btn-full-width-2" type="submit">Submit</button>
													</div>
												</form>
											</div>
											{/* additional-info */}
											<div className="additional-info d-none">
												<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
												<div className="payment-method">
													<img src={publicUrl + "assets/img/icons/payment.png"} alt="#" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>)
}
export default Login