import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Register from './section-components/register';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import Guess from './global-components/guess';

const RegisterV1 = () => {
    return <Guess>
        <div>
            <TopBar />
            <Navbar />
            <PageHeader headertitle="Registrarme" subheader="Register" />
            <Register />
            <Footer />
        </div>
    </Guess>
}

export default RegisterV1

