import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../contexts/LayoutContext';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getLanguage, toastAction } from '../../utils/functions';
import HomeService from '../../services/HomeService';
import { useQuery } from '../../hooks/useQuery';
let anchor = '#'
let imagealt = 'image'
let publicUrl = process.env.PUBLIC_URL + '/'
let clicked = false;

const Sidebar = ({ setParams }) => {
	const { t } = useTranslation()
	const zoneRef = useRef();
	const dateRef = useRef();
	const typeRef = useRef();
	const universitesRef = useRef();
	const amountRef = useRef();
	const bathroomRef = useRef();
	const sexRef = useRef();
	const pbRef = useRef();
	const orderRef = useRef();
	const coupleRef = useRef();
	const history = useHistory()
	const { search, setRooms, rooms } = useLayout()

	const [zones, setZones] = useState(null);
	const [dates, setDates] = useState(null);
	const [universities, setUniversities] = useState(null);
	const [sex, setGenre] = useState(null);
	const [searchFormStatus, setSearchFormStatus] = useState(true);
	const location = useLocation();
	const params = useQuery();

	const [copied, setCopied] = useState(false);
	useEffect(() => {
		Promise.all([
			HomeService.getZones(),
			HomeService.getUniversities(),
			HomeService.getDates(),
			HomeService.getGenre()
		]).then((values) => {
			setTimeout(() => {
				const setters = [setZones, setUniversities, setDates, setGenre];
				for (const i in values) {
					setters[i](values[i]?.data);
				}
			}, 1000);
		}).catch(_ => {
		}).finally(() => setSearchFormStatus(false))
	}, [])

	useEffect(() => {
		!!params.size && !clicked && !rooms && searchData(Object.fromEntries(params.entries())) && history.replace({ pathname: location.pathname });
		clicked = true;
	}, [params])


	useEffect(() => {
		//Zones
		if (zones) {
			const ul = zoneRef.current.querySelector('ul.list')
			let label = '';
			if (ul?.childElementCount === 1) {
				//zone
				ul.innerHTML += zones.reduce((prev, current) => {
					if (current?.id == params.get('zone')) {
						label = current?.zone;
					}

					return prev + `<li data-value="${current?.id}" class="option">${current?.zone}<div></div></li>`;
				}, '');
				label && (zoneRef.current.querySelector('span').textContent = label);
			}

		}
		//Dates
		if (dates) {
			const ul = dateRef.current.querySelector('ul.list')
			let label = '';
			if (ul?.childElementCount === 1) {
				//date
				ul.innerHTML += dates.reduce((prev, current) => {
					if (current?.id == params.get('date')) {
						label = current?.name;
					}
					return prev + `<li data-value="${current?.id}" class="option">${current?.name}<div></div></li>`;
				}, '');
				label && (dateRef.current.querySelector('span').textContent = label);
			}


		}
		//Universities
		if (universities) {
			const ul = universitesRef.current.querySelector('ul.list')
			if (ul?.childElementCount === 1) {
				//univ
				let label = '';
				ul.innerHTML += universities.reduce((prev, current) => {
					if (current?.id == params.get('univ')) {
						label = current?.university;
					}

					return prev + `<li data-value="${current?.id}" class="option">${current?.university}<div></div></li>`;
				}, '');
				label && (universitesRef.current.querySelector('span').textContent = label);
			}
		}

		if (sex) {
			const ul = sexRef.current.querySelector('ul.list')
			if (ul?.childElementCount === 1) {
				//sex
				let label = '';
				ul.innerHTML += sex.reduce((prev, current) => {
					if (current?.id == params.get('sex')) {
						label = getLanguage() == 'es' ? current?.sex : current?.sex_en;
					}
					return prev + `<li data-value="${current?.id}" class="option ${current?.id == params.get('sex') ? 'selected' : ''}">${getLanguage() == 'es' ? current?.sex : current?.sex_en}<div></div></li>`
				}, '');
				label && (sexRef.current.querySelector('span').textContent = label);
			}
		}

	}, [zones, dates, universities, sex])

	const updateQueryParams = (newParams) => {
		const searchParams = new URLSearchParams(window.location.search);

		// Actualizar los nuevos query parameters
		Object.keys(newParams).forEach((key) => {
			if (newParams[key]) {
				searchParams.set(key, newParams[key]);
			} else {
				searchParams.delete(key);
			}
		});
		// Actualizar la URL con los nuevos query parameters
		history.push({ search: searchParams.toString() });
	};

	const searchData = data => {
		toastAction(search(data), 'Cargando...').then(response => {
			setRooms(response?.data);
		}).catch(el => console.log(el))
	}
	const handleShare = (texto = 'hola mundo') => {
		navigator.clipboard.writeText(texto)
			.then(function () {
				setCopied(true)
			})
			.catch(function (error) {

			});
	}

	const handleSubmit = e => {
		e.preventDefault()
		const zone = zoneRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const type = typeRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const date = dateRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const univ = universitesRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const order = orderRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		let price = e.target.querySelector('input[name="price"]')?.value;
		const rooms = amountRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const bathroom = bathroomRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const sex = sexRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const private_bathroom = pbRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		const couple = coupleRef.current.querySelector('li.selected').getAttribute('data-value') || null;
		//In memory

		const data = {};
		if (!isNaN(zone)) {
			Object.assign(data, { zone })
		} else {
			Object.assign(data, { zone: '' })
		}


		if (!isNaN(type)) {
			Object.assign(data, { type })
		} else {
			Object.assign(data, { type: '' })
		}
		if (!isNaN(date)) {
			Object.assign(data, { date })
		} else {
			Object.assign(data, { date: '' })

		}
		if (!isNaN(univ)) {
			Object.assign(data, { univ })
		} else {
			Object.assign(data, { univ: '' })
		}

		if (!isNaN(order)) {
			Object.assign(data, { order })
		} else {
			Object.assign(data, { order: '' })
		}

		if (!isNaN(rooms)) {
			Object.assign(data, { rooms })
		} else {
			Object.assign(data, { rooms: '' })
		}

		if (!isNaN(bathroom)) {
			Object.assign(data, { bathroom })
		} else {
			Object.assign(data, { bathroom: '' })

		}

		if (!isNaN(sex)) {
			Object.assign(data, { sex })
		} else {
			Object.assign(data, { sex: '' })
		}

		if (!isNaN(private_bathroom)) {
			Object.assign(data, { private_bathroom })
		} else {
			Object.assign(data, { private_bathroom: '' })
		}

		if (!isNaN(couple)) {
			Object.assign(data, { couple })
		} else {
			Object.assign(data, { couple: '' })

		}

		if (price) {
			const regex = /€(\d+)\D+€(\d+)/;
			const matches = price.match(regex);
			if (matches && matches.length >= 3) {
				price = [matches[1], matches[2]];
				(price[1] && price[1] != 0) && Object.assign(data, { price })
			}
		}

		setParams(data)
		updateQueryParams(data)
		searchData(data)
	}

	useEffect(() => {
		const $ = window.$;
		$(".slider-range").slider({
			range: true,
			min: 250,
			max: 5000,
			values: [250, 1700],
			slide: function (event, ui) {
				$(".amount").val("€" + ui.values[0] + " - €" + ui.values[1]);
			}
		});
		$(".amount").val("€" + $(".slider-range").slider("values", 250) +
			" - €" + $(".slider-range").slider("values", 1));
	}, [])


	return (
		<div className="col-lg-4  mb-100">
			<aside className="sidebar ltn__shop-sidebar">
				<h3 className="mb-10">Encuentre su habitaci&oacute;n</h3>
				{/* 				<label className="mb-30"><small>About 9,620 results (0.62 seconds) </small></label> */}



				{/* Advance Information widget */}
				<form className="widget ltn__menu-widget" onSubmit={handleSubmit} >
					<h4 className="ltn__widget-title">Tipo</h4>
					<div action="#" className="ltn__car-dealer-form-box row">
						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-apparment col-lg-12 col-md-12" ref={typeRef}>
							<select className="">
								<option>Tipo de alojamiento</option>
								<option value={2} selected={params.get('type') == 2}>Rooms</option>
								<option value={3} selected={params.get('type') == 3}>Coliving</option>
								<option value={4} selected={params.get('type') == 4}>Studio</option>
								<option value={5} selected={params.get('type') == 5}>Landlord</option>
								<option>Indiferente</option>
							</select>
						</div>
						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-zone col-lg-12 col-md-12" ref={zoneRef}>
							<select className="nice-select ">
								<option>{t('home.zone')}</option>
							</select>
						</div>
						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-12 col-md-12" ref={dateRef}>
							<select className="nice-select ">
								<option>{t('home.date')}</option>
							</select>
						</div>
						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-school col-lg-12 col-md-12" ref={universitesRef}>
							<select className="nice-select ">
								<option>{t('home.university')}</option>
							</select>
						</div>
					</div>
					{/* 	<hr /> */}
					{/* Price Filter Widget */}
					<div className="widget--- ltn__price-filter-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border---">Precio</h4>
						<div className="price_filter">
							<div className="price_slider_amount">
								<input type="text" className="amount" name="price" placeholder="Rango de precios" />
							</div>
							<div className="slider-range" />
						</div>
					</div>
					<h4 className="">Ordenar por</h4>
					<div action="#" className="ltn__car-dealer-form-box row">
						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-apparment col-lg-12 col-md-12" ref={orderRef}>

							<select className="">
								<option>Ordenar</option>
								<option value={1}>Menor a mayor</option>
								<option value={2}>Mayor a menor</option>
							</select>
						</div>
					</div>

					<h4 className="ltn__widget-title">Habitaciones</h4>
					<div action="#" className="ltn__car-dealer-form-box row">

						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-apparment col-lg-12 col-md-12" ref={amountRef}>

							<select className="">
								<option>Máximo de habitaciones</option>
								{
									[1, 2, 3, 4, 5, 6, 7].map(el => <option key={`room-${el}`} value={el}>{el}</option>)
								}
								<option>Indiferente</option>
							</select>
						</div>
					</div>
					<h4 className="">Compartir ba&ntilde;o m&aacute;ximo con:</h4>
					<div action="#" className="ltn__car-dealer-form-box row">
						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-apparment col-lg-12 col-md-12" ref={bathroomRef}>
							<select className="">
								<option>Indiferente</option>
								<option value={1}>1 personas</option>
								<option value={2}>2 personas</option>
								<option value={3}>3 personas</option>

							</select>
						</div>
					</div>
					<h4 className="ltn__widget-title">G&eacute;nero</h4>
					<div action="#" className="ltn__car-dealer-form-box row">
						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-apparment col-lg-12 col-md-12" ref={sexRef}>
							<select className="">
								<option>Indiferente</option>
							</select>
						</div>
					</div>

					<h4 className="ltn__widget-title">Ba&ntilde;o Privado</h4>
					<div action="#" className="ltn__car-dealer-form-box row">
						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-apparment col-lg-12 col-md-12" ref={pbRef}>
							<select className="" name='private_bathroom'>
								<option>Indiferente</option>
								<option value={0} >No</option>
								<option value={1} selected={params.get('private_bathroom') == 1}>S&iacute;</option>
							</select>
						</div>
					</div>

					<h4 className="ltn__widget-title">En parejas</h4>
					<div action="#" className="ltn__car-dealer-form-box row">
						<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-apparment col-lg-12 col-md-12" ref={coupleRef}>
							<select className="" name='couple'>
								<option>Indiferente</option>
								<option value={0} valule>No</option>
								<option value={1} selected={params.get('couple') == 1} valule>S&iacute;</option>
							</select>
						</div>
					</div>

					<div className="btn-wrapper text-center mt-0 go-top w-100">
						<button type="submit" className="btn theme-btn-1 w-100 btn-effect-1">{t('home.search')}</button>
					</div>
					<button type='button' onClick={() => handleShare(window.location.href)} className='cursor-pointer mt-4 d-flex gap-2 align-items-center px-3 text-done bg-transparent w-100 justify-content-center'>
						{!copied ? <>
							<>Copiar</>
							<i className='fa fa-copy' />
						</> : <>
							<>Copiado</>
							<i className='fa fa-check' />
						</>}
					</button>

				</form>
				{/* Banner Widget */}
				<div className="widget ltn__banner-widget d-none">
					<a href="shop.html"><img src="img/banner/banner-2.jpg" alt="#" /></a>
				</div>
			</aside>
		</div>
	)
}


export default Sidebar;
