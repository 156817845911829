import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import languageDetector from "i18next-browser-languagedetector"
import es from "./es"
import en from "./en"
import { getLanguage } from "../utils/functions"


i18next
    .use(languageDetector)
    .use(initReactI18next).init({
        resources: {
            es, en
        },
        lng: getLanguage(),
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false
        }
    })