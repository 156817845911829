import React from "react";


const ErrorMessage = ({ message }) => {

    // eslint-disable-next-line react/react-in-jsx-scope
    return <small className="validation-error">
        {
            message
        }
    </small>
}

export default ErrorMessage;