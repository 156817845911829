import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Faq from './section-components/faq-v1';
import Counter from './section-components/counter-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import FeaturedItemV1 from './section-components/featured-item-v1';
import HomeService from '../services/HomeService';

const FaqV1 = () => {
    return <div>
        <TopBar />
        <Navbar />
        <PageHeader headertitle="Preguntas Frequentes" subheader="FAQ" />
        <Faq />
        {/*         <Counter /> */}
        {/*      <BlogSlider sectionClass="pt-120" /> */}
        {/*         <FeaturedItemV1 customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---" /> */}
        <Footer />
    </div>
}

export default FaqV1

