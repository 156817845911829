import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Login from './section-components/login';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import { useAuth } from '../contexts/AuthContextProvider';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import Guess from './global-components/guess';
import { useTranslation } from 'react-i18next';

const LoginV1 = () => {
    const { t } = useTranslation()
    return <Guess>
        <div>
            <TopBar />
            <Navbar />
            <PageHeader headertitle={t('account.title')} subheader="Login" />
            <Login />
            <Footer />
        </div>
    </Guess>
}

export default LoginV1

