import React from 'react';
import { Link } from 'react-router-dom';
import { appName } from '../../utils/environment';
import { useTranslation } from 'react-i18next';


let publicUrl = process.env.PUBLIC_URL + '/'


const CopyRight = () => {

	const { t } = useTranslation();
	return (<div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
		<div className="container-fluid ltn__border-top-2">
			<div className="row">
				<div className="col-md-6 col-12">
					<div className="ltn__copyright-design clearfix">
						<p>{t('home.footer.copyrigth')} @ {appName} <span className="current-year" /></p>
					</div>
				</div>
				<div className="col-md-6 col-12 align-self-center">
					<div className="ltn__copyright-menu text-end">
						<ul className="go-top">
							<li><Link to="/terms">{t('home.footer.terms')}</Link></li>
							<li><Link to="/cookies">{t('home.footer.cookies')}</Link></li>
							<li><Link to="/legal-warning">{t('home.footer.legalWarning')}</Link></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	)
}

export default CopyRight